import { capitalize } from '@evelia/common/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import authorActions from '../actions/authorActions'
import systemMessageActions from '../actions/systemMessageActions'
import { createFileLinkApi, normalizeFileLinkResponse } from '../api/fileLinkApiHelper'
import { getSystemMessageLinkApi } from '../api/systemMessageApi'
import { fileCreated, filesAdded, fileUpdated } from '../slices/filesSlice'
import { defaultEmbeddedNormalizer, getMemoApi } from './apiHelpers'
import { addSuccessNotification } from './notificationHelpers'
import {
  checkPermission,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  createSocketWatcherWithGenerator,
  genericSagaErrorHandler,
  getMinMemoAccessLevel,
  getPromiseHandlersFromData,
  getSubFilesSagas,
  getSubSagas
} from './sagaHelpers'

export const memoApiHandler = mainAction => function* ({ data, authors }) {
  yield put(mainAction(data))
  if(authors) {
    yield put(authorActions.fetchSuccess(authors))
  }
  return data
}

export const getMemoSagas = ({
  actions,
  baseName,
  titleGenetive,
  apiBaseUrl,
  socketName
}) => {
  const memoApi = getMemoApi(apiBaseUrl || baseName)
  const memoSagas = getSubSagas({
    mainActions: actions,
    mainReduxName: baseName,
    subReduxName: 'memos'
  }, {
    fetchApi: memoApi.fetch,
    createApi: memoApi.create,
    updateApi: memoApi.update,
    deleteApi: memoApi.remove
  }, {
    singular: `${capitalize(titleGenetive)} muistiorivi`,
    accusative: `${capitalize(titleGenetive)} muistiorivin`,
    fetchError: `Virhe ${titleGenetive} muistioiden noutamisessa`,
    deleteSuccess: `${capitalize(titleGenetive)} muistiorivi poistettu`,
    deleteError: `Virhe ${titleGenetive} muistiorivin poistamisessa`
  }, memoApiHandler)

  const watchOnMemoSockets = createSocketWatcherWithApiHandlerAndNormalizer(`memo:${socketName}`, actions.memos, memoApiHandler, defaultEmbeddedNormalizer)

  return function* memoSagaRunner() {
    yield takeEvery(actions.memos.actionTypes.fetchRequest, checkPermission(getMinMemoAccessLevel, memoSagas.subFetchFlow))
    yield takeEvery(actions.memos.actionTypes.updateRequest, checkPermission(getMinMemoAccessLevel, memoSagas.subUpdateFlow))
    yield takeEvery(actions.memos.actionTypes.createRequest, checkPermission(getMinMemoAccessLevel, memoSagas.subCreateFlow))
    yield takeLatest(actions.memos.actionTypes.deleteRequest, checkPermission(getMinMemoAccessLevel, memoSagas.subDeleteFlow))

    yield all([
      watchOnMemoSockets()
    ])
  }
}

export const getFileLinkSagas = ({
  actions,
  baseName,
  baseIdFieldName,
  titleGenetive,
  socketName,
  apiBaseUrl = null
}) => {
  const fileLinkApi = createFileLinkApi(apiBaseUrl || baseName, baseIdFieldName)

  const fileFlows = getSubFilesSagas({
    mainActions: actions,
    fetchApi: fileLinkApi.fetch,
    createApi: fileLinkApi.create,
    deleteApi: fileLinkApi.remove,
    mainIdFieldName: baseIdFieldName,
    mainReduxName: baseName,
    messages: {
      singular: `${capitalize(titleGenetive)} tiedoston`,
      accusative: `${capitalize(titleGenetive)} tiedoston`,
      fetchError: `Virhe ${titleGenetive} tiedostojen noutamisessa`,
      deleteSuccess: `${capitalize(titleGenetive)} tiedosto poistettu`,
      deleteError: `Virhe ${titleGenetive} tiedostojen poistamisessa`
    },
    apiHandler: function* ([fileLinks, files]) {
      yield put(actions.files.fetchSuccess(fileLinks))
      yield put(filesAdded(files.map(file => {
        const { _embedded, ...fileData } = file
        return fileData
      })))
    }
  })

  function* fileLinkUpdateFlow({ record, data = {} }) {
    const { resolve, reject } = getPromiseHandlersFromData(data)
    try {
      const fileLinkData = yield call(fileLinkApi.update, record)
      yield put(actions.files.updateSuccess(fileLinkData))
      addSuccessNotification(`${capitalize(titleGenetive)} tiedosto päivitetty`)
      yield call(resolve, fileLinkData)
    } catch(err) {
      yield put(actions.files.updateError(miniSerializeError(err), record))
      yield * genericSagaErrorHandler(err, `Virhe ${titleGenetive} tiedoston muokkaamisessa`, reject)
    }
  }

  const watchOnFileLinkSockets = createSocketWatcherWithGenerator(`fileLink:${socketName}`, {
    created: function* (data) {
      const [fileLink, file] = normalizeFileLinkResponse(data)
      yield put(actions.files.createSuccess(fileLink))
      yield put(fileCreated(file))
    },
    updated: function* (data) {
      const [fileLink, file] = normalizeFileLinkResponse(data)
      yield put(actions.files.updateSuccess(fileLink))
      yield put(fileUpdated(file))
    },
    deleted: function* (data) {
      yield put(actions.files.deleteSuccess(data))
    }
  })

  return function* fileLinkSagaRunner() {
    yield takeLatest(actions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
    yield takeEvery(actions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
    yield takeLatest(actions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)
    yield takeLatest(actions.files.actionTypes.updateRequest, fileLinkUpdateFlow)

    yield all([
      watchOnFileLinkSockets()
    ])
  }
}

const systemMessageLinkApiHandler = mainAction => function* ({ data, systemMessages }) {
  yield put(mainAction(data))
  yield put(systemMessageActions.fetchSuccess(systemMessages))
  return systemMessages
}
const systemMessageLinkMutateApiHandler = mainAction => function* ({ data, systemMessages }) {
  if(Array.isArray(data)) {
    yield all(data.map(record => put(mainAction(record))))
  } else {
    yield put(mainAction(data))
  }
  yield put(systemMessageActions.fetchSuccess(systemMessages))
  return systemMessages
}

export const getSystemMessageSagas = ({
  actions,
  baseName,
  titleGenetive,
  apiBaseUrl,
  socketName
}) => {
  const systemMessageApi = getSystemMessageLinkApi(apiBaseUrl || baseName)
  const systemMessageSagas = getSubSagas({
    mainActions: actions,
    mainReduxName: baseName,
    subReduxName: 'systemMessages'
  }, {
    fetchApi: systemMessageApi.fetch,
    createApi: systemMessageApi.create,
    updateApi: systemMessageApi.update,
    deleteApi: systemMessageApi.remove
  }, {
    singular: `${capitalize(titleGenetive)} viesti`,
    accusative: `${capitalize(titleGenetive)} viestin`,
    fetchError: `Virhe ${titleGenetive} viestien noutamisessa`,
    deleteSuccess: `${capitalize(titleGenetive)} viesti poistettu`,
    deleteError: `Virhe ${titleGenetive} viestin poistamisessa`
  },
  systemMessageLinkApiHandler,
  systemMessageLinkMutateApiHandler)

  const watchOnSystemMessageSockets = createSocketWatcher(`systemMessage:${socketName}`, {
    created: ({ record }) => actions.systemMessages.createSuccess(record),
    updated: ({ record }) => actions.systemMessages.updateSuccess(record)
  })

  return function* systemMessageSagaRunner() {
    yield takeEvery(actions.systemMessages.actionTypes.fetchRequest, systemMessageSagas.subFetchFlow)
    yield takeEvery(actions.systemMessages.actionTypes.updateRequest, systemMessageSagas.subUpdateFlow)
    yield takeEvery(actions.systemMessages.actionTypes.createRequest, systemMessageSagas.subCreateFlow)
    yield takeLatest(actions.systemMessages.actionTypes.deleteRequest, systemMessageSagas.subDeleteFlow)

    yield all([
      watchOnSystemMessageSockets()
    ])
  }
}
