import { getSelf } from '@evelia/common/helpers'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import accountActions from '../actions/accountActions'
import reportActions from '../actions/reportActions'
import { fetchReport, generateReport } from '../api/reportApi'
import { actionTypes } from '../constants'
import { defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { genericSagaErrorHandler, getPromiseHandlersFromData } from '../helpers/sagaHelpers'
import { getReportIdentifier, reportConstants } from '../selectors/reportSelectors'
import { fileCreated } from '../slices/filesSlice'

const apiResponseNormalizersByReportType = {
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.accounting)]: defaultEmbeddedNormalizer,
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.salaryList)]: defaultEmbeddedNormalizer,
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.paymentList)]: defaultEmbeddedNormalizer
}

const defaultHandler = function* ({ data }, requestData) {
  yield put(reportActions.fetchSuccess(data, requestData))
  return data
}

const apiResponseHandlersByReportType = {
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.accounting)]: function* ({
    data,
    accounts
  }, requestData) {
    yield put(accountActions.fetchSuccess(accounts))
    yield put(reportActions.fetchSuccess(data, requestData))
    return data
  },
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.salaryList)]: defaultHandler,
  [getReportIdentifier(reportConstants.salaryCalculations.context, reportConstants.salaryCalculations.reportTypes.paymentList)]: defaultHandler
}

function* reportFetchFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const reportIdentifier = getReportIdentifier(data.reportContext, data.reportType)
  try {
    yield put(reportActions.fetchStart(data))
    const normalizer = apiResponseNormalizersByReportType[reportIdentifier] ?? getSelf
    const report = normalizer(yield call(fetchReport, data))
    if(apiResponseHandlersByReportType[reportIdentifier]) {
      yield call(apiResponseHandlersByReportType[reportIdentifier], report, data)
    } else {
      yield put(reportActions.fetchSuccess(report, data))
    }
    resolve(report)
  } catch(err) {
    yield put(reportActions.generateError(err, data))
    yield * genericSagaErrorHandler(err, 'Virhe raportin noutamisessa', reject)
  }
}

function* reportExportFileDataFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const report = yield call(fetchReport, data)
    resolve(report)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe tiedoston generoimisessa', reject)
  }
}

function* reportGenerateFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(reportActions.generateStart(data))
    const fileData = yield call(generateReport, data)
    yield put(reportActions.generateSuccess(data))
    if(!data.ignoreFetchSuccess) {
      yield put(fileCreated(fileData))
    }
    resolve(fileData)
  } catch(err) {
    yield put(reportActions.generateError(err, data))
    yield * genericSagaErrorHandler(err, 'Virhe raportin luomisessa', reject)
  }
}

export default function* reportSaga() {
  yield takeEvery(actionTypes.REPORTS_FETCH_REQUEST, reportFetchFlow)
  yield takeEvery(actionTypes.REPORTS_GENERATE_REQUEST, reportGenerateFlow)
  yield takeLatest(actionTypes.REPORTS_GENERATE_REPORT_EXPORT_REQUEST, reportExportFileDataFlow)
}
