import { getTypeConfigs } from '@evelia/common/annotationHelpers'
import { formatWithUnit } from '@evelia/common/helpers'
import type {
  BaseElement,
  DrawElement,
  MeasureLineElement,
  Point,
  RisingPointElement,
  ShapeElement,
  TempElement,
  TypelessElement
} from '@evelia/common/types'
import type {
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons'
import {
  faBlockBrick,
  faCube,
  faDrawCircle,
  faDrawPolygon,
  faPeriod,
  faRuler,
  faRulerVertical
} from '@fortawesome/pro-solid-svg-icons'

import { buildSquare } from './projectionHelpers'

export const typeMap: Record<DrawElement['type'], string> = {
  point: 'piste',
  line: 'viiva',
  area: 'alue',
  squareArea: 'suorakulmio',
  measureLine: 'mittaviiva',
  volume: 'tilavuus',
  circleArea: 'ympyrä',
  circleAreaTemp: 'ympyrä',
  wall: 'seinä',
  risingPoint: 'nousu',
  typeless: 'ei piirretty'
}

export const getTempShapeCoordinates = (element: TempElement): number[] => {
  if(!('coordinates' in element) || !element.coordinates) { return [] }

  return element.coordinates.flat()
}

export const getTempPointCoordinates = (element: Omit<TempElement, 'coordinates'> & { coordinates?: Point[] }): Point[] => {
  if(!('coordinates' in element) || !element.coordinates) { return [] }
  return element.coordinates
}

type DefaultProps = {
  zIndex: number
  offerAnnotationFileId: number
  page: number
  offerPostId: number
} & Partial<DrawElement>

const getDefaultProps = (defaultProps: DefaultProps): BaseElement & Partial<DrawElement> => ({
  id: TEMP_ELEMENT_DEFAULT_ID,
  packetId: null,
  installationId: null,
  type: 'typeless',
  color: null,
  updatedAt: '',
  createdAt: '',
  offerPostRowId: null,
  description: null,
  productId: null,
  normHourSum: 0,
  isComment: false,
  isExpenses: false,
  isSubcontracting: false,
  ...defaultProps
})

export const initializeMeasureLine = ({ page, zIndex, offerAnnotationFileId }: Omit<DefaultProps, 'offerPostId'>): MeasureLineElement => {
  const defaultProps = getDefaultProps({
    page,
    offerAnnotationFileId,
    zIndex,
    offerPostId: 0
  })
  return {
    ...defaultProps,
    type: 'measureLine',
    coordinates: []
  }
}

export const TEMP_ELEMENT_DEFAULT_ID = 0
export const createDrawElementFromType = (type: Exclude<DrawElement['type'], 'measureLine'>, _defaultProps: DefaultProps): DrawElement | undefined => {
  const defaultProps = getDefaultProps(_defaultProps)
  switch(type) {
    case 'squareArea':
    case 'point':
      return {
        ...defaultProps,
        type,
        coordinates: []
      }
    case 'line':
    case 'area':
      return {
        ...defaultProps,
        type,
        coordinates: [],
        lineThickness: null
      }
    case 'circleAreaTemp':
      return {
        ...defaultProps,
        type,
        coordinates: null,
        radius: null
      }
    case 'volume':
    case 'wall':
      return {
        ...defaultProps,
        type,
        coordinates: [],
        height: null,
        lineThickness: null
      }
    case 'typeless':
      return {
        ...defaultProps,
        type
      }
    case 'risingPoint':
      if('parentShapeId' in defaultProps && defaultProps.parentShapeId) {
        return {
          ...defaultProps,
          type,
          distance: 'distance' in defaultProps && defaultProps.distance ? defaultProps.distance : 0,
          length: null,
          index: 'index' in defaultProps && defaultProps.index ? defaultProps.index : 0,
          parentShapeId: defaultProps.parentShapeId
        }
      }
      return undefined
    default:
      return undefined
  }
}
export const transformTypelessElementToDrawElement = (type: string, element: TypelessElement): DrawElement | null => {
  switch(type) {
    case 'point':
      return {
        ...element,
        coordinates: [],
        type
      }
    case 'area':
    case 'line':
      return {
        ...element,
        lineThickness: null,
        coordinates: [],
        type
      }
    case 'wall':
    case 'volume':
      return {
        ...element,
        coordinates: [],
        height: null,
        lineThickness: null,
        type
      }
    case 'squareArea': {
      return {
        coordinates: [],
        ...element,
        type
      }
    }
    case 'circleAreaTemp': {
      return {
        ...element,
        coordinates: null,
        radius: null,
        type
      }
    }
    default:
      return null
  }
}

export const transformTempElementToDrawElement = (element: TempElement): ShapeElement | null => {
  switch(element.type) {
    case 'point':
    case 'area':
    case 'line':
    case 'volume':
    case 'wall':
      return element
    case 'squareArea':
      if(element.coordinates.length === 2) {
        const [start, end] = element.coordinates
        const coordinates = buildSquare(start, end)
        return {
          lineThickness: null,
          ...element,
          type: 'area',
          coordinates
        }
      }
      return null
    case 'circleAreaTemp': {
      if(element.coordinates && element.radius !== null) {
        return {
          ...element,
          coordinates: element.coordinates,
          radius: element.radius,
          type: 'circleArea'
        }
      }
      return null
    }
    case 'risingPoint':
    case 'typeless': {
      return element
    }
    default:
      return null
  }
}

export const pageFormatter = formatWithUnit('.')

export const isMeasureLine = (element: TempElement): element is MeasureLineElement => {
  return element.type === 'measureLine'
}

export type NestedShapeList = (ShapeElement & { subElements?: ShapeElement[] })[]

export const createNestedShapeList = (elements: ShapeElement[]): NestedShapeList => {
  const outputElements = new Map<number, ShapeElement & { subElements?: ShapeElement[] }>(
    elements.map(element => [element.id, { ...element }])
  )
  const shapesWithParentShape = elements.filter(element => 'parentShapeId' in element && element.parentShapeId !== null) as RisingPointElement[]

  for(const shape of shapesWithParentShape) {
    if(outputElements.has(shape.parentShapeId)) {
      const parentElement = outputElements.get(shape.parentShapeId)
      if(parentElement) {
        if(parentElement.subElements) {
          parentElement.subElements.push(shape)
        } else {
          parentElement.subElements = [shape]
        }
        outputElements.delete(shape.id)
      }
    }
  }

  const output = [...outputElements.values()]

  return output
}

export const getTypeIcon = (type: DrawElement['type']): IconDefinition | null => {
  switch(type) {
    case 'line':
      return faRuler
    case 'area':
      return faDrawPolygon
    case 'point':
      return faPeriod
    case 'wall':
      return faBlockBrick
    case 'volume':
      return faCube
    case 'risingPoint':
      return faRulerVertical
    case 'circleAreaTemp':
    case 'circleArea':
      return faDrawCircle
    default:
      return null
  }
}

export const getTotalShapeAmountGrouped = (elements: ShapeElement[], pixelsPerMeter: number): Record<string, number> => {
  return elements.reduce((acc, element) => {
    const config = getTypeConfigs(element.type)
    if(!config) { return acc }

    const value = config.getValue(element, pixelsPerMeter) ?? 0

    acc[config.unit] = (acc[config.unit] ?? 0) + value

    return acc
  }, {} as Record<string, number>)
}

export const getShapeRowId = ({ id }: { id: number }): string => {
  return `shape-row-${id}`
}

export const getIfTempElementRequiresTypeSelection = (tempElement: TempElement | undefined): boolean =>
  !!tempElement &&
 tempElement?.type !== 'measureLine' &&
 !tempElement.packetId &&
 !tempElement.productId &&
 !tempElement.isComment &&
 !tempElement.offerPostRowId &&
 !tempElement.drawing

export const getFlattenedShapeList = (elements: NestedShapeList): ShapeElement[] => {
  const output: ShapeElement[] = []

  for(const element of elements) {
    output.push(element)
    if(element.subElements) {
      output.push(...element.subElements)
    }
  }

  return output
}
