import type { BaseIdModel } from '@evelia/common/types'

import { createApi, defaultNormalizer } from '../helpers/apiHelpers'

export type FederatedCredentialModel = {
  email: string
  provider: 1
} & BaseIdModel

const baseUrl = '/api/sso'

export const ssoAuthLoginUrl = authService => {
  return `${baseUrl}/auth/${authService}`
}

export const federatedCredentialApi = createApi({
  base: `auth/federated_credentials`,
  normalizer: defaultNormalizer
})
