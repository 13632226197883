import { actionTypes } from '../constants'

const reportReducer = (state = {}, action) => {
  if(!action.data) {
    return state
  }
  const newState = {
    ...state
  }
  const reportKey = action.data.reportContext + '__' + action.data.reportType
  switch(action.type) {
    case actionTypes.REPORTS_FETCH_START:
      newState[reportKey] = {
        ...state[reportKey],
        busy: true
      }
      return newState
    case actionTypes.REPORTS_FETCH_SUCCESS:
      newState[reportKey] = {
        busy: false,
        data: action.reportData || state[reportKey].data
      }
      return newState
    case actionTypes.REPORTS_FETCH_ERROR:
      newState[reportKey] = {
        busy: false,
        error: action.error
      }
      return newState
    case actionTypes.REPORTS_GENERATE_START:
      newState[reportKey] = {
        ...state[reportKey],
        isGenerating: true
      }
      return newState
    case actionTypes.REPORTS_GENERATE_SUCCESS:
      newState[reportKey] = {
        isGenerating: false,
        data: action.reportData || state[reportKey].data
      }
      return newState
    case actionTypes.REPORTS_GENERATE_ERROR:
      newState[reportKey] = {
        isGenerating: false,
        error: action.error
      }
      return newState
    default:
      return state
  }
}

export default reportReducer
