import type { ReactNode } from 'react'
import isFunction from 'lodash/isFunction'

import withPermissionCheck from './withPermissionCheck'

const Permission = ({ children }: { children?: ReactNode | (() => ReactNode) }) => isFunction(children) ? children() : children

const withPermission = (featureKeyOrAccessLevel: string | number) => withPermissionCheck(featureKeyOrAccessLevel, Permission)

export default withPermission
