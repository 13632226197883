import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import targetActions from '../actions/targetActions'
import {
  createContact,
  createContactFile,
  deleteContact,
  deleteContactFile,
  fetchContactCustomers,
  fetchContactFiles,
  fetchContacts,
  fetchContactStats,
  fetchContactTargets,
  searchContact,
  updateContact
} from '../api/contactApi'
import contactExtraApi from '../api/contactExtraApi'
import {
  createFlow,
  createSocketWatcher,
  deleteFlow,
  fetchFlow,
  fetchStatsFlow,
  genericSagaErrorHandler,
  getSubFilesSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnSockets = createSocketWatcher('contact', {
  created: contactActions.createSuccess,
  updated: contactActions.updateSuccess
})

const contactFetchFlow = fetchFlow({
  fetchApi: fetchContacts,
  actions: contactActions,
  base: 'contacts',
  errorMsg: 'Yhteyshenkilöiden'
})

const contactUpdateFlow = updateFlow(updateContact, contactActions, 'Yhteyshenkilö', 'Yhteyshenkilön')
const contactCreateFlow = createFlow(createContact, contactActions, 'Yhteyshenkilö', 'Yhteyshenkilön')
const contactSearchFlow = searchFlow(searchContact, contactActions, 'Yhteyshenkilöiden')
const contactDeleteFlow = deleteFlow({
  deleteApi: deleteContact,
  actions: contactActions,
  singular: 'Yhteyshenkilö',
  errorMsg: 'Yhteyshenkilön',
  base: 'contacts'
})

const fileFlows = getSubFilesSagas({
  mainActions: contactActions,
  fetchApi: fetchContactFiles,
  createApi: createContactFile,
  deleteApi: deleteContactFile,
  mainIdFieldName: 'contactId',
  mainReduxName: 'contacts',
  messages: {
    singular: 'Yhteyshenkilön tiedosto',
    accusative: 'Yhteyshenkilön tiedoston',
    fetchError: 'Virhe yhteyshenkilön tiedostojen noutamisessa',
    deleteSuccess: 'Yhteyshenkilön tiedostolinkki poistettu',
    deleteError: 'Virhe yhteyshenkilön tiedostolinkin poistamisessa'
  }
})

const getContactSubFetchSaga = (fetchApi, subReduxName, mainSubReduxName, subActions, message) => {
  return function* ({ data = {} }) {
    const { contactId } = data
    try {
      yield put(contactActions[subReduxName].fetchStart())
      const apiResponse = yield call(fetchApi, contactId)
      yield put(contactActions[subReduxName].fetchSuccess(apiResponse[mainSubReduxName]))
      yield put(subActions.fetchSuccess(apiResponse[subReduxName]))
    } catch(err) {
      yield * genericSagaErrorHandler(err, message)
    }
  }
}

const contactCustomerFetchFlow = getContactSubFetchSaga(fetchContactCustomers, 'customers', 'contactCustomers', customerActions, 'Virhe yhteyshenkilön asiakkaiden noutamisessa')
const contactTargetFetchFlow = getContactSubFetchSaga(fetchContactTargets, 'targets', 'contactTargets', targetActions, 'Virhe yhteyshenkilön kohteiden noutamisessa')

const contactStatsFetchFlow = fetchStatsFlow(contactActions, fetchContactStats, 'contactId')

const contactExtrasFetchFlow = fetchFlow({
  fetchApi: contactExtraApi.fetch,
  actions: contactActions.contactExtras,
  base: 'contacts.contactExtras',
  errorMsg: 'henkilön lisäasetusten'
})

const contactExtrasUpdateFlow = updateFlow(contactExtraApi.update, contactActions.contactExtras, 'Henkilön lisäasetus', 'Henkilön lisäasetuksen')
const contactExtrasCreateFlow = createFlow(contactExtraApi.create, contactActions.contactExtras, 'Henkilön lisäasetus', 'Henkilön lisäasetuksen')

export default function* contactSaga() {
  yield takeLatest(contactActions.actionTypes.fetchRequest, contactFetchFlow)
  yield takeEvery(contactActions.actionTypes.updateRequest, contactUpdateFlow)
  yield takeEvery(contactActions.actionTypes.createRequest, contactCreateFlow)
  yield takeLatest(contactActions.actionTypes.searchRequest, contactSearchFlow)
  yield takeEvery(contactActions.actionTypes.deleteRequest, contactDeleteFlow)

  yield takeLatest(contactActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(contactActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(contactActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(contactActions.customers.actionTypes.fetchRequest, contactCustomerFetchFlow)
  yield takeLatest(contactActions.targets.actionTypes.fetchRequest, contactTargetFetchFlow)

  yield takeLatest(contactActions.actionTypes.fetchStatsRequest, contactStatsFetchFlow)

  yield takeLatest(contactActions.contactExtras.actionTypes.fetchRequest, contactExtrasFetchFlow)
  yield takeEvery(contactActions.contactExtras.actionTypes.updateRequest, contactExtrasUpdateFlow)
  yield takeEvery(contactActions.contactExtras.actionTypes.createRequest, contactExtrasCreateFlow)

  yield watchOnSockets()
}
