import { isDefined } from '@evelia/common/helpers'
import type { ContactModel } from '@evelia/common/types'

import {
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getStats,
  getSubentitySelectors
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { selectFileEntities } from '../slices/filesSlice'

export const getContactsFromArgument = arg => arg.contacts ? arg.contacts.records : arg

export const findContactWithId = getFindItemByIdSelector(getContactsFromArgument)

export const getContactFileLinks = createArgumentSelector(
  (state: EveliaRootState, __contactId) => state.contacts.files.records,
  (__state, contactId: ContactModel['id']) => contactId,
  (fileLinks, contactId) => fileLinks.filter(fileLink => fileLink.contactId === contactId)
)

export const getFilesOfContact = createArgumentSelector(
  selectFileEntities,
  getContactFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

export const getContactsFromSearchResult = getItemsFromSearchResultsSelector('contacts', getContactsFromArgument, findContactWithId)

export const {
  getSubItemsOfItem: getCustomersOfContact
} = getSubentitySelectors('contacts', 'customers', 'customers', 'contactId', 'customerId')

export const {
  getSubItemsOfItem: getTargetsOfContact
} = getSubentitySelectors('contacts', 'targets', 'targets', 'contactId', 'targetId')

export const getStatsOfContact = getStats(findContactWithId, 'contacts', 'contactId')
