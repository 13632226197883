import { createCachedSelector } from 're-reselect'

import type { EveliaRootState } from '../reducerTypes'

export const getReportIdentifier = (context: string, type: string) => `${context}__${type ?? context}`

export const reportConstants = {
  salaryCalculations: {
    context: 'salary_calculation',
    reportTypes: {
      accounting: 'accounting',
      salaryList: 'salary_list',
      paymentList: 'payment_list',
      vacation: 'vacation'
    }
  }
}

export const findReports = createCachedSelector(
  (state: EveliaRootState) => state.reports,
  (__state, context: string, type: string) => getReportIdentifier(context, type),
  (reports, key) => {
    const report = reports[key] || { busy: true }
    return report
  }
)((state, context, type) => getReportIdentifier(context, type))
