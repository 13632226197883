import { calculateDistance } from '@evelia/common/annotationHelpers'
import type { Point } from '@evelia/common/types'
import type { KonvaEventObject } from 'konva/lib/Node'
import type { Stage } from 'konva/lib/Stage'
import type { Vector2d } from 'konva/lib/types'

export const projectPoint = (stage: Stage, point: Vector2d) => {
  const scale = stage.scaleX()
  return {
    x: (point.x - stage.x()) / scale,
    y: (point.y - stage.y()) / scale
  }
}

export const getPointFromEvent = (event: KonvaEventObject<unknown>) => {
  const stage = event.target.getStage()

  if(!stage) { return }

  const point = stage.getPointerPosition()

  if(!point) { return }

  const projectedPoint = projectPoint(stage, point)
  return projectedPoint ? [projectedPoint.x, projectedPoint.y] as Point : undefined
}

// 2d plane
const chunkSize = 2
export const transformCoordinateListToPointList = (coordinates: number[]) => {
  const points: Point[] = []
  for(let i = 0; i < coordinates.length; i += chunkSize) {
    const chunk = coordinates.slice(i, i + chunkSize)
    points.push(chunk as Point)
  }
  return points
}

const getDistanceFromPointToLine = (point: Point, lineStart: Point, lineEnd: Point) => {
  const [x0, y0] = point
  const [x1, y1] = lineStart
  const [x2, y2] = lineEnd

  // Calculate the numerator of the distance formula
  const numerator = Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1)

  // Calculate the denominator of the distance formula
  const denominator = Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2)

  // Distance is numerator divided by denominator
  return numerator / denominator
}

export const getClosestLineIndexFromPoint = (point: Point, line: Point[]) => line.reduce((prev, curr, index) => {
  const nextLinePoint = line[index + 1]
  if(nextLinePoint) {
    const distanceToPoint = getDistanceFromPointToLine(point, curr, nextLinePoint)
    if(prev.distance === null || distanceToPoint < prev.distance) {
      return {
        distance: distanceToPoint,
        index
      }
    }
  }
  return prev
}, { index: 0, distance: null as null | number })

export const handleElementClick = (callback: (event: KonvaEventObject<MouseEvent>) => void) => (event: KonvaEventObject<MouseEvent>) => {
  if(event.evt.button !== 0) {
    return
  }

  return callback(event)
}

export const getPointAtDistance = (p1: Point, p2: Point, distance: number): Point => {
  // Calculate the vector from p1 to p2
  const dx = p2[0] - p1[0]
  const dy = p2[1] - p1[1]

  // Scale the vector to the desired distance
  const scaledDx = dx * distance / 100
  const scaledDy = dy * distance / 100

  // Add the scaled vector to p1 to find the new point
  const newX = p1[0] + scaledDx
  const newY = p1[1] + scaledDy

  return [newX, newY]
}

export const getDistanceToClosestPointOnLineSegment = (pointA: Point, pointB: Point, mousePos: Point) => {
  const [x1, y1] = pointA // Line start point
  const [x2, y2] = pointB // Line end point
  const [px, py] = mousePos // Mouse position

  // Line segment vector
  const dx = x2 - x1
  const dy = y2 - y1
  const lineDistance = calculateDistance([pointA, pointB])

  // Compute the t value for the projection of the point onto the line
  const t = ((px - x1) * dx + (py - y1) * dy) / (dx * dx + dy * dy)

  // Clamp t to [0, 1] to ensure the point is on the segment
  const tClamped = Math.max(0, Math.min(1, t))

  // Closest point on the line segment
  const closestX = x1 + tClamped * dx
  const closestY = y1 + tClamped * dy

  // Distance from the point to the closest point
  const distance = Math.sqrt((closestX - x1) ** 2 + (closestY - y1) ** 2)
  const distancePercentage = distance / lineDistance * 100

  return distancePercentage
}

export const buildSquare = (point1: Point, point2: Point): [Point, Point, Point, Point] => {
  return [
    [point1[0], point1[1]],
    [point2[0], point1[1]],
    [point2[0], point2[1]],
    [point1[0], point2[1]]
  ]
}

export const calculateClosestPointAndDistanceOnCircle = (
  circleCenter: Point,
  radius: number,
  mousePosition: Point
): { closestPoint: Point, distance: number } => {
  // Vector from the circle center to the mouse position
  const dx = mousePosition[0] - circleCenter[0]
  const dy = mousePosition[1] - circleCenter[1]

  // Distance from circle center to the mouse position
  const distanceToMouse = Math.sqrt(dx * dx + dy * dy)

  // Normalize the vector (dx, dy)
  const scale = radius / distanceToMouse

  // Closest point on the circle's perimeter
  const closestPoint: Point = [
    circleCenter[0] + dx * scale,
    circleCenter[1] + dy * scale
  ]

  // Distance from the mouse position to the closest point
  const distance = Math.abs(distanceToMouse - radius)

  return { closestPoint, distance }
}

const padding = 10
export const buildBoundingBoxCoordinates = (points: Point[]): number[] => {
  const maxY = Math.max(...points.map(c => c[1])) + padding
  const minY = Math.min(...points.map(c => c[1])) - padding
  const maxX = Math.max(...points.map(c => c[0])) + padding
  const minX = Math.min(...points.map(c => c[0])) - padding

  const square = buildSquare([minX, minY], [maxX, maxY])
  return [...square.flat(), ...square[0]]
}

export const getCentroidOfPolygon = (points: Point[]): Point => {
  let A = 0; let Cx = 0; let Cy = 0

  const l = points.length

  for(let i = 0; i < l; i++) {
    const x0 = points[i][0]
    const y0 = points[i][1]
    const x1 = points[(i + 1) % l][0]
    const y1 = points[(i + 1) % l][1]

    const crossProduct = x0 * y1 - x1 * y0
    A += crossProduct
    Cx += (x0 + x1) * crossProduct
    Cy += (y0 + y1) * crossProduct
  }

  A *= 0.5
  Cx /= (6 * A)
  Cy /= (6 * A)

  return [Cx, Cy]
}

const getBearing = (point1: Point, point2: Point): number => {
  const angleRad = Math.atan2(point2[1] - point1[1], point2[0] - point1[0])
  const angleDeg = angleRad * (180 / Math.PI)
  return (angleDeg + 360) % 360
}

const getMidpoint = (point1: Point, point2: Point): Point => {
  return [
    (point1[0] + point2[0]) / 2,
    (point1[1] + point2[1]) / 2
  ]
}
const getPointFromBearing = (point: Point, bearing: number, distance: number) => {
  const angleRad = bearing * (Math.PI / 180) // Convert degrees to radians

  const x = point[0] + distance * Math.cos(angleRad)
  const y = point[1] + distance * Math.sin(angleRad)

  return [x, y] as Point
}

export const getLineLabelCoordinates = (points: Point[], offset: number): { point: Point, rotation: number } => {
  const startingCoordinateIndex = Math.floor(points.length / 2)
  const startingCoordinate = points[startingCoordinateIndex]
  const endingCoordinate = points[startingCoordinateIndex + 1]
  const bearing = getBearing(startingCoordinate, endingCoordinate)

  const midPoint = getMidpoint(startingCoordinate, endingCoordinate)
  const isOnNegativeSide = bearing < 90 || bearing > 280

  const pointDirectionBearing = isOnNegativeSide ? bearing - 90 : bearing + 90
  const point = getPointFromBearing(midPoint, pointDirectionBearing, offset)

  return { point, rotation: (isOnNegativeSide) ? bearing : bearing - 180 }
}
