import type { BaseIdModel } from '@evelia/common/types'
import type {
  EntityState
} from '@reduxjs/toolkit'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import type { RootState } from '../reducerTypes'
import { getFindToolkitFileLinksByPropertySelector } from '../selectors/fileSelectors'

const getFileLinkSlice = <T extends BaseIdModel>(name: string, selector: (state: RootState) => EntityState<T, T['id']>) => {
  const fileLinkEntityAdapter = createEntityAdapter<T>({})

  const fileLinkSlice = createGenericSlice({
    name,
    entityAdapter: fileLinkEntityAdapter
  })

  const fileLinkEntitySelectors = fileLinkEntityAdapter.getSelectors(selector)
  const fileLinkActions = fileLinkSlice.actions

  const findFileLinksByPropertySelector = getFindToolkitFileLinksByPropertySelector(fileLinkEntitySelectors.selectAll)

  return { fileLinkSlice, fileLinkEntitySelectors, fileLinkActions, findFileLinksByPropertySelector }
}

export default getFileLinkSlice
