import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  Col,
  Form,
  FormGroup,
  FormText,
  Row
} from 'reactstrap'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'

import FormInput from '../hookForm/FormInput'
import { objectValidator, stringValidators } from '../hookForm/validationHelpers'

const digitRegex = /^\d{6}$/

const resolver = yupResolver(objectValidator({
  totpToken: stringValidators.safeRequired('Koodi')
    .matches(digitRegex, { message: 'Koodi pitää olla 6-merkkinen numero' })
}))

const TotpInput = ({ setTotpToken, isError, handleVerifyOtp, testId = 'mfaTotpInput' }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState,
    setFocus,
    getValues,
    clearErrors
  } = useForm({
    defaultValues: { totpToken: null },
    resolver
  })

  useEffect(() => {
    setFocus('totpToken')
  }, [setFocus])

  const { isValid } = formState
  useEffect(() => {
    if(isValid) {
      const totpToken = getValues('totpToken')
      setTotpToken(totpToken)
      clearErrors('totpToken')
    } else {
      setTotpToken(null)
      clearErrors('totpToken')
    }
  }, [clearErrors, getValues, isValid, setTotpToken])

  const handlePaste = async e => {
    e.preventDefault()
    const inputValue = e.clipboardData.getData('Text').replace(/\D/g, '')
    setValue('totpToken', inputValue, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <Form onSubmit={handleSubmit(handleVerifyOtp)} data-testid={`${testId}`}>
      <FormGroup className='totp-container'>
        <FormInput
          inputProps={{
            placeholder: 'Syötä 6-numeroinen koodi',
            maxLength: 6,
            onPaste: handlePaste,
            bsSize: 'lg',
            autoComplete: 'off'
          }}
          name='totpToken'
          control={control}
          prepend={<FontAwesomeIcon icon={faKey} />}
        />
        {isError
          ? (
            <Row>
              <Col>
                <FormText color='danger' className='p-0' data-testid={`${testId}ErrorMessage`}>Virheellinen vahvistuskoodi</FormText>
              </Col>
            </Row>
            )
          : null}
      </FormGroup>
    </Form>
  )
}

TotpInput.propTypes = {
  setTotpToken: PropTypes.func,
  handleVerifyOtp: PropTypes.func,
  isError: PropTypes.bool,
  testId: PropTypes.string
}

export default TotpInput
