import { toIdMap } from '@evelia/common/helpers'
import { createSelector } from 'reselect'

import type { CustomerModel } from '../api/rtk/customerApi'
import type { CustomerFileLinkModel } from '../api/rtk/types/fileApi'
import {
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getStats,
  getSubentitySelectors
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'

const argIsState = (arg: EveliaRootState | CustomerModel[] | CustomerFileLinkModel[]): arg is EveliaRootState => 'customers' in arg

export const getCustomersFromArgument = (arg: EveliaRootState | CustomerModel[]) => argIsState(arg) ? arg.customers.records : arg
export const getCustomerFileLinksFromArgument = (arg: EveliaRootState | CustomerFileLinkModel[]) => argIsState(arg) ? arg.customers.files.records : arg

export const findCustomerWithId = getFindItemByIdSelector(getCustomersFromArgument)

export const {
  getSubItemsOfItem: getContactsOfCustomer,
  rejectSubItemsOfItem: rejectContactsOfCustomer
} = getSubentitySelectors('customers', 'contacts', 'contacts', 'customerId', 'contactId')

export const getCustomerFileLinks = createArgumentSelector(
  (state: EveliaRootState, __customerId: number) => getCustomerFileLinksFromArgument(state),
  (__state: EveliaRootState, customerId: number) => customerId,
  (fileLinks, customerId) => fileLinks.filter(fileLink => fileLink.customerId === customerId)
)

export const findMemosByCustomerId = getMemoSelector('customers', 'customerId')

export const getCustomersFromSearchResult = getItemsFromSearchResultsSelector('customers', getCustomersFromArgument, findCustomerWithId)

export const getStatsOfCustomer = getStats(findCustomerWithId, 'customers', 'customerId')

export const getCustomersMap = createSelector(
  getCustomersFromArgument,
  customers => toIdMap(customers)
)
