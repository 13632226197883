import { Badge } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import type { ProjectModel } from '@evelia/common/types'
import { faProjectDiagram, faUserTie } from '@fortawesome/free-solid-svg-icons'

import { contentRowWithIcon } from '../../components/Details/ProjectDetails'
import { useTypedSelector } from '../../reducerTypes'
import { findCustomerWithId } from '../../selectors/customerSelectors'
import { findProjectWithId } from '../../selectors/projectSelectors'
import { findTargetWithId } from '../../selectors/targetSelectors'

interface ProjectSearchItemProps {
  item: ProjectModel
}

export const ProjectSearchItem = ({ item }: ProjectSearchItemProps) => {
  // @ts-expect-error selectors number of arguments are incorrectly inferred
  const mainProjectName = useTypedSelector(state => item.projectPath.length > 1 ? findProjectWithId(state, item.projectPath[0]) : null)?.name ?? null
  // @ts-expect-error selectors number of arguments are incorrectly inferred
  const customerOfProject = useTypedSelector(state => item.customerId ? findCustomerWithId(state, item.customerId) : null)
  // @ts-expect-error selectors number of arguments are incorrectly inferred
  const targetOfProject = useTypedSelector(state => item.targetId ? findTargetWithId(state, item.targetId) : null)
  const address = targetOfProject?.address || customerOfProject?.address
  const postalCode = targetOfProject?.postalCode || customerOfProject?.postalCode
  const city = targetOfProject?.city || customerOfProject?.city
  return (
    <span>
      <span className='d-inline-flex align-items-center'>
        {item.projectNumber ? <Badge className='me-1' color='info'>{item.projectNumber}</Badge> : null}
        {item.name}
      </span>
      <br />
      <small>{contentRowWithIcon(mainProjectName, faProjectDiagram)}</small>
      <small>{contentRowWithIcon(customerOfProject?.name, faUserTie)}</small>
      {(address || postalCode || city) && (
        <small>{appendEitherOrEmpty(address, postalCode, ' | ')} {city || ''}</small>
      )}
    </span>
  )
}

export default ProjectSearchItem
