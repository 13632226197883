import type { EmploymentSalaryTypeModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import type { EveliaRootState } from '../reducerTypes'

const employmentSalaryTypeAdapter = createEntityAdapter<EmploymentSalaryTypeModel, string>({ selectId: employmentSalaryType => employmentSalaryType._id })

const employmentSalaryTypeSlice = createGenericSlice({
  name: 'employmentSalaryTypes',
  entityAdapter: employmentSalaryTypeAdapter
})

export const employmentSalaryTypeEntitySelectors = employmentSalaryTypeAdapter.getSelectors<EveliaRootState>(state => state.wageEarners.employmentSalaryTypes)
export const employmentSalaryTypeActions = employmentSalaryTypeSlice.actions

export default employmentSalaryTypeSlice
