import React from 'react'
import type { ElementType, ReactNode } from 'react'

import { useTypedSelector } from '../../reducerTypes'
import { getHasAccessToFeature } from '../../selectors/whoAmISelectors'

type FeatureProps = {
  children: ReactNode
  hide?: boolean
  FallbackComponent?: ElementType
  fallback?: ReactNode
  ignoreAccessLevel?: boolean
  featureKey?: string
  minAccessLevel?: number
} & Record<string, unknown>

const Feature = React.memo(({
  children,
  hide,
  FallbackComponent,
  fallback,
  ignoreAccessLevel,
  featureKey,
  minAccessLevel,
  ...restProps
}: FeatureProps) => {
  // @ts-expect-error selectors are not typed
  const hasAccess = useTypedSelector(state => getHasAccessToFeature(state, featureKey, ignoreAccessLevel, minAccessLevel))

  return hasAccess
    ? children
    : hide
      ? <div className='d-none'>{children}</div>
      : fallback || (FallbackComponent ? <FallbackComponent {...restProps} /> : null)
})

export default Feature
