import type { ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'
import isEqual from 'lodash/isEqual'

import supplyOfferActions from '../../actions/supplyOfferActions'
import type { ApiResponse, KanbanQuery, KanbanQuickFilter, KanbanQuickFilterCreateBody } from '.'
import { getBaseQuery, kanbanQuery } from './apiHelpers'
import type { SupplyOfferModel } from './supplyOfferApi'
import { handleDispatchEmbeddedData, supplyOfferApi } from './supplyOfferApi'

export type SupplyOfferKanbanModel = {
  records: SupplyOfferModel[]
  totalCount: number
  mainFilter: string
  column: string | number
}

export type SupplyOfferKanbanQuickFilter = KanbanQuickFilter<'supplyOffer'>

export const supplyOfferKanbanApi = createApi({
  reducerPath: 'supplyOfferKanbanApi',
  baseQuery: getBaseQuery('supply_offers/kanban'),
  tagTypes: ['quick_filters'],
  endpoints: builder => ({
    getSupplyOfferKanban: builder.query<ApiResponse<SupplyOfferKanbanModel>, KanbanQuery>({
      query: kanbanQuery,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg?.extraFilters, previousArg?.extraFilters) ||
           !isEqual(currentArg?.sort, previousArg?.sort) ||
           currentArg?.mainFilter !== previousArg?.mainFilter ||
           !isEqual(currentArg?.columns, previousArg?.columns)
      }
    }),
    getSupplyOfferKanbanQuickFilters: builder.query<SupplyOfferKanbanQuickFilter[], void>({
      query: constant('/quick_filters'),
      providesTags: [{ type: 'quick_filters' }]
    }),
    getSupplyOfferKanbanQuickFilter: builder.query<SupplyOfferKanbanQuickFilter, number>({
      query: query => `/quick_filters/${query}`,
      providesTags: (__result, __error, id) => [{ type: 'quick_filters', id }]
    }),
    createSupplyOfferKanbanQuickFilter: builder.mutation<SupplyOfferKanbanQuickFilter, KanbanQuickFilterCreateBody >({
      query: body => ({
        url: '/quick_filters',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'quick_filters' }]
    }),
    updateSupplyOfferKanbanQuickFilter: builder.mutation<SupplyOfferKanbanQuickFilter, SupplyOfferKanbanQuickFilter>({
      query: body => ({
        url: `/quick_filters/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    }),
    deleteSupplyOfferKanbanQuickFilter: builder.mutation<void, number>({
      query: id => ({
        url: `/quick_filters/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (__result, __error, id) => [{ type: 'quick_filters' }, { type: 'quick_filters', id }]
    })
  })
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: supplyOfferKanbanApi.endpoints.getSupplyOfferKanban.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      listenerApi.dispatch(supplyOfferApi.util.resetApiState())
      for(const record of records) {
        const { mainFilter, column } = record
        const data: ApiResponse<SupplyOfferModel> = {
          records: record.records,
          _embedded: {
            options: {
              totalCount: record.totalCount
            }
          }
        }
        const upsertQueryData = supplyOfferApi.util.upsertQueryData('getSupplyOffers', { mainFilter: [mainFilter, column], cacheBust: true }, { ...data })
        // Something weird happens here with typescript
        listenerApi.dispatch(upsertQueryData as ThunkAction<unknown, unknown, unknown, UnknownAction>)
        listenerApi.dispatch(supplyOfferActions.fetchSuccess(record.records))
      }
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: supplyOfferKanbanApiMiddleware } = listenerMiddleware

export const {
  useGetSupplyOfferKanbanQuery,
  useGetSupplyOfferKanbanQuickFiltersQuery,
  useCreateSupplyOfferKanbanQuickFilterMutation,
  useUpdateSupplyOfferKanbanQuickFilterMutation,
  useDeleteSupplyOfferKanbanQuickFilterMutation
} = supplyOfferKanbanApi
