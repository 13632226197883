import { castToArray } from '@evelia/common/helpers'
import type {
  AnnotationFile,
  AnnotationFilePage,
  ShapeElement
} from '@evelia/common/types'
import { combineSlices, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { offerAnnotationApi } from '../api/rtk/offerAnnotationApi'
import { createNestedShapeList } from '../components/Annotation/utils/shapeHelpers'
import { createEveliaSelector } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'

const annotationFileAdapter = createEntityAdapter<AnnotationFile>({})

const annotationFileSlice = createSlice({
  name: 'annotationFiles',
  initialState: annotationFileAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(offerAnnotationApi.endpoints.getAnnotationFiles.matchFulfilled, (state, action) => {
      annotationFileAdapter.addMany(state, action.payload.records)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.updateAnnotationFile.matchFulfilled, (state, action) => {
      annotationFileAdapter.upsertOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.createAnnotationFile.matchFulfilled, (state, action) => {
      annotationFileAdapter.upsertOne(state, action.payload.record)
    })
  }
})

const annotationFilePageAdapter = createEntityAdapter<AnnotationFilePage, string>({
  selectId: page => `${page.offerAnnotationFileId}_${page.page}`
})

const annotationFilePageSlice = createSlice({
  name: 'annotationFilePages',
  initialState: annotationFilePageAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(offerAnnotationApi.endpoints.getAnnotationPages.matchFulfilled, (state, action) => {
      annotationFilePageAdapter.addMany(state, action.payload.records)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.getAnnotationPage.matchFulfilled, (state, action) => {
      annotationFilePageAdapter.addOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.createAnnotationPage.matchFulfilled, (state, action) => {
      annotationFilePageAdapter.upsertOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.updateAnnotationPage.matchFulfilled, (state, action) => {
      annotationFilePageAdapter.upsertOne(state, action.payload.record)
    })
  }
})

const annotationShapeAdapter = createEntityAdapter<ShapeElement>()

const annotationShapesSlice = createSlice({
  name: 'annotationShapes',
  initialState: annotationShapeAdapter.getInitialState(),
  reducers: {
    updateAnnotationShape: annotationShapeAdapter.updateOne
  },
  extraReducers: builder => {
    builder.addMatcher(offerAnnotationApi.endpoints.getAnnotationShapes.matchFulfilled, (state, action) => {
      annotationShapeAdapter.addMany(state, action.payload.records)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.getAnnotationShape.matchFulfilled, (state, action) => {
      annotationShapeAdapter.addOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.createAnnotationShape.matchFulfilled, (state, action) => {
      annotationShapeAdapter.upsertOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.updateAnnotationShape.matchFulfilled, (state, action) => {
      annotationShapeAdapter.upsertOne(state, action.payload.record)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.deleteAnnotationShape.matchFulfilled, (state, action) => {
      annotationShapeAdapter.removeOne(state, action.meta.arg.originalArgs.id)
    })
    builder.addMatcher(offerAnnotationApi.endpoints.sendShapeToBack.matchFulfilled, (state, action) => {
      const record = action.payload.record
      const samePageShapes = Object.values(state.entities).filter(({ offerAnnotationFileId, page }) => offerAnnotationFileId === record.offerAnnotationFileId && page === record.page)
      state.entities[record.id] = record

      for(const shape of samePageShapes) {
        if(shape.id !== record.id) {
          state.entities[shape.id] = { ...shape, zIndex: shape.zIndex < record.zIndex ? shape.zIndex + 1 : shape.zIndex }
        }
      }
    })
    builder.addMatcher(offerAnnotationApi.endpoints.bringShapeToFront.matchFulfilled, (state, action) => {
      const record = action.payload.record
      const samePageShapes = Object.values(state.entities).filter(({ offerAnnotationFileId, page }) => offerAnnotationFileId === record.offerAnnotationFileId && page === record.page)
      state.entities[record.id] = record

      for(const shape of samePageShapes) {
        if(shape.id !== record.id) {
          state.entities[shape.id] = { ...shape, zIndex: shape.zIndex > record.zIndex ? shape.zIndex - 1 : shape.zIndex }
        }
      }
    })
  }
})

export const { updateAnnotationShape } = annotationShapesSlice.actions

export const { selectEntities: selectAnnotationFileEntities } = annotationFileAdapter.getSelectors<EveliaRootState>(state => state.offerAnnotation[annotationFileSlice.reducerPath])
export const selectAnnotationFileById = createEveliaSelector(
  selectAnnotationFileEntities,
  (state: EveliaRootState, id: number | undefined) => id,
  (entities, id) => id != null ? entities[id] : null
)

export const { selectEntities: selectAnnotationFilePageEntities } = annotationFilePageAdapter.getSelectors<EveliaRootState>(state => state.offerAnnotation[annotationFilePageSlice.reducerPath])
export const selectAnnotationFilePageByFileIdAndPage = createEveliaSelector(
  selectAnnotationFilePageEntities,
  (state: EveliaRootState, annotationFileId: number | undefined, page: number) => annotationFileId ? `${annotationFileId}_${page}` : null,
  (entities, id) => id != null ? entities[id] : null
)

export const { selectEntities: selectAnnotationShapeEntities, selectIds: selectAnnotationShapeIds } = annotationShapeAdapter.getSelectors<EveliaRootState>(state => state.offerAnnotation[annotationShapesSlice.reducerPath])
export const selectOfferPostAnnotationShapes = createEveliaSelector(
  selectAnnotationShapeEntities,
  (state: EveliaRootState, params: { ids: number[], offerPostId: number | undefined }) => params.offerPostId,
  (state: EveliaRootState, params: { ids: number[], offerPostId: number | undefined }) => params.ids,
  (entities, offerPostId, ids) => {
    const entitiesWithOfferPostId = Object.values(entities).filter(shape =>
      shape.offerPostId === offerPostId && ids.includes(shape.id)
    )
    return entitiesWithOfferPostId.map(({ id }) => id)
  }
)

export const selectAnnotationShapesByIds = createEveliaSelector(
  selectAnnotationShapeEntities,
  (state: EveliaRootState, ids: number[]) => ids,
  (entities, ids) => ids.map(id => entities[id]).filter(Boolean).sort((a, b) => a.zIndex - b.zIndex)
)

export const selectAnnotationShapesWithField = createEveliaSelector(
  selectAnnotationShapesByIds,
  (state: EveliaRootState, ids: number[], params: { field: keyof ShapeElement | (keyof ShapeElement)[], exclude?: boolean }) => params.field,
  (state: EveliaRootState, ids: number[], params: { field: keyof ShapeElement | (keyof ShapeElement)[], exclude?: boolean }) => params.exclude,
  (shapes, fields, exclude) => createNestedShapeList(shapes.filter(shape => castToArray(fields).every(f => exclude ? !shape[f] : shape[f])))
)
export const selectAnnotationShapeById = createEveliaSelector(
  selectAnnotationShapeEntities,
  (state: EveliaRootState, id: number | undefined) => id ?? null,
  (entities, id) => id != null ? entities[id] : null
)

export const selectAnnotationShapeSubShapes = createEveliaSelector(
  selectAnnotationShapeEntities,
  (state: EveliaRootState, id: number | undefined) => id,
  (entities, id) => Object.values(entities).filter(shape => 'parentShapeId' in shape && shape.parentShapeId === id)
)

const offerAnnotationSlice = combineSlices(annotationFileSlice, annotationFilePageSlice, annotationShapesSlice)

export default offerAnnotationSlice
