import { MixedSchema } from 'yup'

import { moment, withFormat } from '../dateHelpers.js'
import { callFunctionOrContent } from '../helpers.js'
import { locale } from './locale.js'

class MomentDateSchema<TContext> extends MixedSchema<string, TContext> {
  constructor() {
    super({
      type: 'date',
      check: (value): value is string => (moment.isMoment(value) && value.isValid()) || moment(value, [moment.ISO_8601]).isValid()
    })
  }

  min(min: string, message = locale.date.min) {
    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { min },
      skipAbsent: true,
      test(value) {
        return value != null && value >= withFormat(callFunctionOrContent(min))
      }
    })
  }

  max(max: string, message = locale.date.max) {
    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max },
      skipAbsent: true,
      test(value) {
        return value != null && value <= withFormat(callFunctionOrContent(max))
      }
    })
  }
}

export const yupMomentValidator = <TContext>() => new MomentDateSchema<TContext>()
