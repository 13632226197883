import { cssTransition, toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import clsx from 'clsx'

import './notifications.scss'

// Create two distinct transitions because using same keyframes twice in a row doesn't animate anything because of css
const bounceTransitions = Array(2).fill(0).map((_, idx) => cssTransition({
  enter: idx === 0 ? 'bounce' : 'bounce_2',
  exit: 'Toastify__flip-exit Toastify--animate',
  collapse: false
}))

const toastCache = {}
const levels = {
  error: 'error',
  warning: 'warning',
  info: 'info'
}
const addNotification = (message, level, opts = {}) => {
  const toastType = levels[level] ?? 'success'
  const options = toastType === 'success' ? { autoClose: 1000, ...opts } : opts
  const cached = toastCache[message]

  if(cached) {
    cached.count++
    cached.onCloses.push(options.onClose)

    const textClass = toastType === 'error' ? 'text-danger' : `text-${toastType}`
    toast.update(cached.toastId, {
      ...options,
      // Toggle the transition class not to use the same keyframes twice in a row.
      transition: bounceTransitions[toastCache[message].count % 2],
      onClose: props => {
        if(toastCache[message]) {
          toast.dismiss(toastCache[message].toastId)
          delete toastCache[message]
        }
        cached.onCloses.filter(Boolean).forEach(onClose => onClose(props))
      },
      render: (
        <div>
          {message}
          <Badge color='white' pill className={clsx(`pb-1 me-1`, textClass)} style={{ float: 'right' }}>{cached.count}</Badge>
        </div>
      )
    })
    return cached.toastId
  }

  const toastId = toast(message, {
    ...options,
    onClose: props => {
      delete toastCache[message]
      options.onClose?.(props)
    },
    type: toastType
  })
  toastCache[message] = { count: 1, toastId, onCloses: [options.onClose], options }
  return toastId
}

const findCacheKeyFromToastId = toastId => {
  return Object.keys(toastCache).find(message => toastCache[message]?.toastId === toastId)
}

export const addErrorNotification = (message, opts = {}) => {
  return addNotification(message, 'error', { autoClose: 5000, ...opts })
}

export const addSuccessNotification = (message, opts = {}) => {
  return addNotification(message, 'success', opts)
}

export const addWarningNotification = (message, opts = {}) => {
  return addNotification(message, 'warning', { autoClose: 5000, ...opts })
}

export const addInfoNotification = (message, opts = {}) => {
  return addNotification(message, 'info', { autoClose: 5000, ...opts })
}

export const updateNotification = (toastId, toastOptions = {}) => {
  return toast.update(toastId, {
    ...toastOptions,
    onClose: props => {
      const cacheKey = findCacheKeyFromToastId(toastId)
      if(cacheKey) {
        toastCache[cacheKey]?.onCloses.filter(Boolean).forEach(onClose => onClose(props))
        delete toastCache[cacheKey]
      }
    }
  })
}

export const removeNotification = toastId => {
  const cacheKey = findCacheKeyFromToastId(toastId)
  if(cacheKey) {
    delete toastCache[cacheKey]
  }
  return toast.dismiss(toastId)
}
