import { isDefined } from '@evelia/common/helpers'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getStats,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import { selectFileEntities } from '../slices/filesSlice'
import { findPacketIndustryWithId, getPacketIndustriesFromArgument } from './systemCustomerSelectors'
import { getWarehousesWithSubWarehouses } from './warehouseSelectors'

const getProductsFromArgument = arg => arg.products ? arg.products.records : arg
const getProductTasksFromArgument = arg => arg.products ? arg.products.productTasks.records : arg

export const findProductWithId = getFindItemByIdSelector(getProductsFromArgument)

export const getProductsByTableIds = getTableIdsSelector('products')

export const getProductsByTableIdsWithCounts = createSelector(
  getProductsByTableIds,
  (state, tableIdentifier, returnEmptyOnEmpty, counts) => counts,
  (products, counts) => {
    return products.map(product => {
      const existingProduct = counts.find(row => row.id === product.id) || { count: null }
      return {
        ...product,
        ...existingProduct
      }
    })
  }
)

export const getProductFileLinks = createArgumentSelector(
  (state, productId) => state.products.files.records,
  (state, productId) => productId,
  (fileLinks, productId) => fileLinks.filter(fileLink => fileLink.productId === productId)
)

export const getFilesOfProduct = createArgumentSelector(
  selectFileEntities,
  getProductFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

export const getProductsFromSearchResult = getItemsFromSearchResultsSelector('products', getProductsFromArgument, findProductWithId)

export const populateProductTasksWithTask = createSelector(
  getProductTasksFromArgument,
  getPacketIndustriesFromArgument,
  (productTasks, packetIndustries) => productTasks.map(populatePacketIndustryDataToProductTask(packetIndustries))
)

const populatePacketIndustryDataToProductTask = state => productTask => {
  if(!productTask) {
    return
  }
  const packetIndustry = findPacketIndustryWithId(state, productTask.packetIndustryId)
  if(!packetIndustry) {
    return { ...productTask, name: null, normHourPrice: null }
  }
  const { name, normHourPrice } = packetIndustry
  return {
    ...productTask,
    name,
    normHourPrice
  }
}

export const getProductTasksOfProduct = createCachedSelector(
  populateProductTasksWithTask,
  (state, productId) => Number(productId),
  (productTasks, productId) => productTasks.filter(productTask => productTask.productId === productId)
)((products, productId) => productId)

export const getStatsOfProduct = getStats(findProductWithId, 'products', 'productId')

export const getProductByTableIdsWithExtraInfo = createSelector(
  getProductsByTableIds,
  state => state.products.extraInfo,
  (__state, __tableIdentifier, productCounts) => productCounts,
  (products, extraInfos, productCounts) => {
    return products.map(product => {
      const warehouseInfos = extraInfos?.filter(row => row.productId === product.id)
      const groupedWarehouseInfos = getWarehousesWithSubWarehouses(warehouseInfos, 'warehouseId')
      const { warehouseSum, inOrderSum, inDeliverySum } = warehouseInfos.length
        ? warehouseInfos.reduce((acc, extraInfo) => {
          acc.warehouseSum += extraInfo.warehouseSum
          if(extraInfo.inOrderSum != null) {
            acc.inOrderSum = (acc.inOrderSum ?? 0) + extraInfo.inOrderSum
          }
          if(extraInfo.inDeliverySum != null) {
            acc.inDeliverySum = (acc.inDeliverySum ?? 0) + extraInfo.inDeliverySum
          }
          return acc
        }, { warehouseSum: 0, inOrderSum: null, inDeliverySum: null })
        : { warehouseSum: null, inOrderSum: null, inDeliverySum: null }
      const { count } = productCounts?.find(row => row.id === product.id) || { count: null }
      return {
        ...product,
        warehouseSum,
        inOrderSum,
        inDeliverySum,
        warehouseInfos,
        groupedWarehouseInfos,
        count
      }
    })
  }
)
