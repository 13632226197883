import { shallowEqual } from 'react-redux'
import { castToArray, getSelf } from '@evelia/common/helpers'
import type { BaseIdModel } from '@evelia/common/types'
import memoize from 'micro-memoize'
import { createCachedSelector } from 're-reselect'
import { createSelector, createSelectorCreator, lruMemoize, weakMapMemoize } from 'reselect'
import type { ConditionalKeys } from 'type-fest'

import type { EveliaRootState, RootState } from '../reducerTypes'

export type StateOrArray<T> = EveliaRootState | T[]

export type GetListFn<T> = (arg: StateOrArray<T>) => T[]

type CastValueFn<TIn, TOut> = (data: TIn) => TOut

export const createEveliaSelector = createSelector.withTypes<EveliaRootState>()

export const createArgumentSelector = createSelectorCreator({
  memoize: weakMapMemoize,
  argsMemoize: lruMemoize,
  argsMemoizeOptions: {
    equalityCheck: shallowEqual,
    resultEqualityCheck: shallowEqual,
    maxSize: 50
  }
}).withTypes<EveliaRootState>()

export const getSubentitySelectors = <TSubItem extends BaseIdModel, TJunction>(junctionItemSelector: (state: RootState) => TJunction[], subItemSelector: (state: RootState) => TSubItem[], mainIdFieldName: ConditionalKeys<TJunction, number>, subIdFieldName: keyof TJunction) => {
  const getJunctionData = createCachedSelector<EveliaRootState, number | string, TJunction[], number, TJunction[]>(
    state => junctionItemSelector(state),
    (__state, mainId) => Number(mainId),
    (junctionItems, mainId) => junctionItems.filter(junctionItem => junctionItem[mainIdFieldName] === mainId)
  )((__state, mainId) => `${mainId}`)

  const getJunctionDataBySubId = createCachedSelector<EveliaRootState, string | number, TJunction[], number, number, TJunction | undefined>(
    state => junctionItemSelector(state),
    (__state, mainId) => Number(mainId),
    (__state, __mainId, subId) => Number(subId),
    (junctionItems, mainId, subId) => junctionItems.find(junctionItem => junctionItem[mainIdFieldName] === mainId && junctionItem[subIdFieldName] === subId)
  )((__state, mainId, subId) => `${mainId}_${subId}`)

  const getSubItemsOfItem = createCachedSelector<EveliaRootState, number | string, TJunction[], TSubItem[], TSubItem[]>(
    (state, mainId) => getJunctionData(state, mainId),
    state => subItemSelector(state),
    (junctionItems, subItems) => {
      const subItemIds = junctionItems.map(junctionItem => junctionItem[subIdFieldName] as number)
      return subItems.filter(subItem => subItemIds.includes(subItem.id))
    }
  )((__state, mainId) => `${mainId}`)

  const rejectSubItemsOfItem = createCachedSelector<EveliaRootState, number | string, TJunction[], TSubItem[], TSubItem[]>(
    (state, mainId) => getJunctionData(state, mainId),
    state => subItemSelector(state),
    (junctionItems, subItems) => {
      const subItemIds = junctionItems.map(junctionItem => junctionItem[subIdFieldName] as number)
      return subItems.filter(subItem => !subItemIds.includes(subItem.id))
    }
  )((__state, mainId) => `${mainId}`)

  return {
    getJunctionData,
    getJunctionDataBySubId,
    getSubItemsOfItem,
    rejectSubItemsOfItem
  }
}

export const getFindItemByIdSelector = <T extends BaseIdModel>(getListFn: GetListFn<T>, fallback?: T) => createCachedSelector(
  [
    getListFn,
    (__state, id: string | number) => Number(id)
  ],
  (records, id) => records.find(record => record.id === id) ?? fallback
)((__state, id) => `${id}`)

export const getFindItemsByIdsSelector = <T extends BaseIdModel>(getListFn: GetListFn<T>, fallback?: T[]) => createCachedSelector(
  getListFn,
  (__state, ids: string[] | number[]) => ids,
  (records, rawIds) => {
    const ids = (rawIds || []).map(id => Number(id))
    return records.filter(record => ids.includes(record.id)) || fallback
  }
)((__state, ids) => `${(ids || []).join(',')}`)

export const getCastedArray = memoize((targetValues, castValues) => castToArray(targetValues).map(castValues))

export const getFilterItemsByFieldSelector = <T extends BaseIdModel, TFieldName extends keyof T, Target = T[TFieldName]>(getListFn: GetListFn<T>, fieldNames: TFieldName[], castValues: CastValueFn<Target, T[TFieldName]> = getSelf as CastValueFn<Target, T[TFieldName]>) => createCachedSelector(
  getListFn,
  (__state, targetValue: Target) => targetValue,
  (records, targetValue) => {
    const targetValues = targetValue === null ? [null] : getCastedArray(targetValue, castValues)
    return records.filter(record => castToArray(fieldNames).every((fieldName, index) => record[fieldName] === targetValues[index]))
  }
)((__state, targetValues) => `${JSON.stringify(targetValues)}`)

export const getFilterItemsByFieldWithValueArraySelector = <T extends BaseIdModel>(getListFn: GetListFn<T>, fieldName: keyof T, castValues = getSelf, sortBy = null) => createCachedSelector(
  getListFn,
  (__state, targetValues) => targetValues,
  (records, targetValuesRaw) => {
    const targetValues = getCastedArray(targetValuesRaw, castValues)
    const values = records.filter(record => targetValues.includes(record[fieldName]))
    return sortBy ? values.sort(sortBy) : values
  }
)((__state, targetValues) => `${JSON.stringify(targetValues)}`)
