import { useDispatch } from 'react-redux'
import type { SalaryPeriodModel } from '@evelia/common/types'
import type {
  PayloadAction
} from '@reduxjs/toolkit'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'
import { push } from 'redux-first-history'

import { createGenericSlice } from '../helpers/sliceHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { PATH_SALARIES } from '../routes'

const salaryPeriodAdapter = createEntityAdapter<SalaryPeriodModel>({})

const salaryPeriodSlice = createGenericSlice({
  name: 'salaryPeriods',
  entityAdapter: salaryPeriodAdapter,
  reducers: {
    doPatchAction: (state, __action: PayloadAction<{ record: Partial<SalaryPeriodModel> }>) => state,
    doPostAction: (state, __action: PayloadAction) => state
  }
})

export const salaryPeriodEntitySelectors = salaryPeriodAdapter.getSelectors<EveliaRootState>(state => state.salaryPeriods.salaryPeriods)
export const salaryPeriodActions = salaryPeriodSlice.actions

const getSalaryPeriodUrl = (salaryPeriodId: number) => `${PATH_SALARIES}/${salaryPeriodId}`

export const useNavigateToSalaryPeriod = () => {
  const dispatch = useDispatch()
  return (id: number) => dispatch(push(getSalaryPeriodUrl(id)))
}

export default salaryPeriodSlice
