import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import costCentreActions from '../../actions/costCentreActions'
import denominationActions from '../../actions/denominationActions'
import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import workActions from '../../actions/workActions'
import { addSuccessNotification } from '../../helpers/notificationHelpers'
import { getBaseQuery } from './apiHelpers'
import type { ApiResponse } from './types/api'

type AutoAssignDenominationsBody = {
  id: number
  force?: boolean
  includeSubProjects?: boolean
}

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: getBaseQuery('projects'),
  endpoints: builder => ({
    autoAssignProjectDenominations: builder.mutation<{ records: { success: number }, _embedded: ApiResponse<unknown>['_embedded'] }, AutoAssignDenominationsBody>({
      query: body => ({
        url: `/${body.id}/auto_denominations`,
        method: 'POST',
        body
      })
    })
  })
})

const listenerMiddlewareAutoAssignProjectDenominations = createListenerMiddleware()

listenerMiddlewareAutoAssignProjectDenominations.startListening({
  matcher: projectApi.endpoints.autoAssignProjectDenominations.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      // Dispatch work into base redux state
      addSuccessNotification(`Projektin litterat asetettu. Päivitetyt rivit: ${records.success}.`)
    }
    if(_embedded) {
      listenerApi.dispatch(costCentreActions.fetchSuccess(_embedded.costCentres ?? []))
      listenerApi.dispatch(denominationActions.fetchSuccess(_embedded.denominations ?? []))
      listenerApi.dispatch(workActions.workRows.fetchSuccess(_embedded.workRows ?? []))
      listenerApi.dispatch(inboundInvoiceActions.rows.fetchSuccess(_embedded.inboundInvoiceRows ?? []))
    }
  }
})

export const { useAutoAssignProjectDenominationsMutation } = projectApi
export const { middleware: autoAssignProjectDenominationsMiddleware } = listenerMiddlewareAutoAssignProjectDenominations
