import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom/client'
import type { Middleware } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import createSagaMiddleware from 'redux-saga'

import 'typeface-open-sans'
import 'typeface-ibm-plex-sans'
import 'typeface-roboto-condensed'
import './styles/index.scss'
import 'dx-react-grid-bootstrap5/dist/dx-react-grid-bootstrap5.css'
import { deliveryTermApi } from './api/rtk/deliveryTermApi'
import { deliveryTypeApi } from './api/rtk/deliveryTypeApi'
import { exportsApi } from './api/rtk/exportsApi'
import { fileApi } from './api/rtk/fileApi'
import { inboundInvoiceApi, inboundInvoiceApiMiddleware } from './api/rtk/inboundInvoiceApi'
import { offerAnnotationApi } from './api/rtk/offerAnnotationApi'
import { productWarehouseApi } from './api/rtk/productWarehouseApi'
import { autoAssignProjectDenominationsMiddleware, projectApi } from './api/rtk/projectApi'
import { purchaseOrderApi } from './api/rtk/purchaseOrderApi'
import { purchaseOrderFileApi, purchaseOrderFileMiddleware } from './api/rtk/purchaseOrderFileApi'
import { purchaseOrderMemoApi, purchaseOrderMemoMiddleware } from './api/rtk/purchaseOrderMemoApi'
import { purchaseOrderRowApi } from './api/rtk/purchaseOrderRowApi'
import { purchaseOrderStateApi } from './api/rtk/purchaseOrderStateApi'
import { supplyOfferApi, supplyOfferApiMiddleware } from './api/rtk/supplyOfferApi'
import { supplyOfferKanbanApi, supplyOfferKanbanApiMiddleware } from './api/rtk/supplyOfferKanbanApi'
import { ticketsApi, ticketsApiMiddleware } from './api/rtk/ticketsApi'
import { ticketsKanbanApi, ticketsKanbanApiMiddleware } from './api/rtk/ticketsKanbanApi'
import { ticketCreateMiddleware, ticketTemplateListApi, ticketTemplatesMiddleware, ticketUpdateMiddleware } from './api/rtk/ticketTemplateListApi'
import { vatCodeApi } from './api/rtk/vatCodeApi'
import { warehouseApi } from './api/rtk/warehouseApi'
import { workApi, workApiMiddleware } from './api/rtk/workApi'
import { workKanbanApi, workKanbanApiMiddleware } from './api/rtk/workKanbanApi'
import { workScheduleApi, workScheduleMiddleware } from './api/rtk/workScheduleApi'
import App from './containers/App'
import reportWebVitals from './reportWebVitals'
import createRootReducer from './rootReducer'
import rootSaga from './rootSaga'

window.onunhandledrejection = ev => {
  Sentry.withScope(scope => {
    const err = ev.reason
    err.validationErrors && scope.setContext('Validation Errors', err.validationErrors)
    err.json && scope.setContext('Error json', err.json)
    const eventId = Sentry.captureException(err)
    console.error(`Sentry event id: ${eventId}`)
  })
}

if(import.meta.env.MODE !== 'development' && import.meta.env.VITE_CYPRESS_BUILD !== 'true') {
  Sentry.init({
    dsn: 'https://5ebc5499e78f45208ea2da99fa39a2f8@sentry.io/2061988',
    release: `${import.meta.env.VITE_GIT_REV}`,
    environment: `${import.meta.env.VITE_ENV}`,
    denyUrls: [
      /tawk\.to/i
    ],
    ignoreErrors: [
      /^loading css chunk \d+ failed/i
    ],
    integrations: [
      Sentry.globalHandlersIntegration({
        onunhandledrejection: false
      }),
      Sentry.browserTracingIntegration()
    ],
    tracesSampleRate: 0.01
  })
}

const history = createBrowserHistory()
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history
})
const sagaMiddleware = createSagaMiddleware()
const middleware = [
  sagaMiddleware,
  routerMiddleware as Middleware,
  fileApi.middleware,
  workApi.middleware,
  workKanbanApi.middleware,
  supplyOfferApi.middleware,
  supplyOfferKanbanApi.middleware,
  ticketsApi.middleware,
  ticketsKanbanApi.middleware,
  inboundInvoiceApi.middleware,
  ticketTemplateListApi.middleware,
  vatCodeApi.middleware,
  purchaseOrderApi.middleware,
  purchaseOrderRowApi.middleware,
  purchaseOrderStateApi.middleware,
  purchaseOrderMemoApi.middleware,
  purchaseOrderFileApi.middleware,
  productWarehouseApi.middleware,
  deliveryTermApi.middleware,
  deliveryTypeApi.middleware,
  exportsApi.middleware,
  projectApi.middleware,
  workScheduleApi.middleware,
  warehouseApi.middleware,
  offerAnnotationApi.middleware,
  workApiMiddleware,
  workKanbanApiMiddleware,
  supplyOfferApiMiddleware,
  supplyOfferKanbanApiMiddleware,
  ticketsApiMiddleware,
  ticketsKanbanApiMiddleware,
  ticketTemplatesMiddleware,
  ticketCreateMiddleware,
  ticketUpdateMiddleware,
  purchaseOrderMemoMiddleware,
  purchaseOrderFileMiddleware,
  autoAssignProjectDenominationsMiddleware,
  inboundInvoiceApiMiddleware,
  workScheduleMiddleware
]
// const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['meta.arg', 'meta.baseQueryMeta', 'data.resolve', 'data.reject', 'payload.resolve', 'payload.reject', 'error']
    }
  }).concat(middleware),
  enhancers: getDefaultEnhancers => getDefaultEnhancers()
})

sagaMiddleware.run(rootSaga)

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <App store={store} history={createReduxHistory(store)} />
)

if(import.meta.env.MODE !== 'development' && import.meta.env.VITE_CYPRESS_BUILD !== 'true') {
  // @ts-expect-error unused var is needed
  // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars, no-var, no-use-before-define
  var Tawk_API = Tawk_API || {}; var Tawk_LoadStart = new Date();
  (function() {
    // eslint-disable-next-line no-var
    var s1 = document.createElement('script'); var s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = 'https://embed.tawk.to/5cece9b6a667a0210d59c2cd/default'
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode?.insertBefore(s1, s0)
  })()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
