import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  error,
  request,
  start,
  success
} from '../helpers/actionHelpers'
import { PATH_INBOUND_INVOICES } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const inboundInvoiceUrlBase = `${PATH_INBOUND_INVOICES}{/id}{?source,type}`
const inboundInvoiceUrlTemplate = parseTemplate(inboundInvoiceUrlBase)

export const getUrl = (id, params) => inboundInvoiceUrlTemplate.expand({ id, ...params })

const baseActionCreators = actionCreatorsFor(constants.actionKeys.INBOUND_INVOICES, { fetchStats: true, fetchExtraInfo: true })

const additionalActionCreators = {
  rows: {
    ...actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_ROWS),
    doPutAction: request(actionTypes.INBOUND_INVOICE_ROWS_PUT_ACTION_REQUEST)
  },
  statuses: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_STATUSES),
  files: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_FILES),
  memos: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_MEMOS),
  approvals: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_APPROVALS),
  approvers: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_APPROVERS),
  accountings: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_ACCOUNTINGS),
  recurrenceRules: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_RECURRENCE_RULES),
  recurrenceOccurrencies: actionCreatorsFor(constants.actionKeys.INBOUND_INVOICE_RECURRENCE_OCCURRENCIES),
  navigateTo: (inboundInvoiceId, replaceUrl, params) => navigateTo(getUrl(inboundInvoiceId, params), replaceUrl),
  navigateToBase: replaceUrl => navigateTo(getUrl(), replaceUrl),
  fetchFromMaventaRequest: request(actionTypes.INBOUND_INVOICES_FETCH_FROM_MAVENTA_REQUEST),
  fetchFromMaventaStart: start(actionTypes.INBOUND_INVOICES_FETCH_FROM_MAVENTA_START),
  fetchFromMaventaSuccess: success(actionTypes.INBOUND_INVOICES_FETCH_FROM_MAVENTA_SUCCESS),
  fetchFromMaventaError: error(actionTypes.INBOUND_INVOICES_FETCH_FROM_MAVENTA_ERROR),
  approveInboundInvoiceRequest: request(actionTypes.INBOUND_INVOICES_APPROVE_REQUEST),
  approveInboundInvoiceStart: start(actionTypes.INBOUND_INVOICES_APPROVE_START),
  approveInboundInvoiceSuccess: success(actionTypes.INBOUND_INVOICES_APPROVE_SUCCESS),
  approveInboundInvoiceError: error(actionTypes.INBOUND_INVOICES_APPROVE_ERROR),
  assignInboundInvoiceRowsRequest: request(actionTypes.INBOUND_INVOICES_ASSIGN_ROWS_REQUEST),
  assignInboundInvoiceRowsStart: start(actionTypes.INBOUND_INVOICES_ASSIGN_ROWS_START),
  assignInboundInvoiceRowsSuccess: success(actionTypes.INBOUND_INVOICES_ASSIGN_ROWS_SUCCESS),
  assignInboundInvoiceRowsError: error(actionTypes.INBOUND_INVOICES_ASSIGN_ROWS_ERROR),

  fetchStatsRequest: request(baseActionCreators.actionTypes.fetchStatsRequest),
  fetchStatsStart: start(baseActionCreators.actionTypes.fetchStatsStart),
  fetchStatsSuccess: success(baseActionCreators.actionTypes.fetchStatsSuccess),
  fetchStatsError: error(baseActionCreators.actionTypes.fetchStatsError),

  doInboundInvoicePostAction: request(actionTypes.INBOUND_INVOICE_POST_ACTION_REQUEST),
  importC2BFromFile: request(actionTypes.INBOUND_INVOICE_C2B_IMPORT_REQUEST),

  tableActions: tableActions(constants.actionKeys.INBOUND_INVOICES),
  fetchExtraInfoSuccess: success(baseActionCreators.actionTypes.fetchExtraInfoSuccess)
}

const inboundInvoiceActions = { ...baseActionCreators, ...additionalActionCreators }
export default inboundInvoiceActions
