import type { EmploymentModel, EmploymentSalaryTypeModel, TaxDeductionCardModel } from '@evelia/common/types'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'

export const wageEarnerApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer
})

const getTaxDeductionCardParams = (params: { wageEarnerId: number, id: number }, model: TaxDeductionCardModel) => ({
  ...params,
  id: model?.wageEarnerId ?? params.wageEarnerId,
  subItem: 'tax_deduction_cards',
  subItemId: params.id
})

export const taxDeductionCardApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getTaxDeductionCardParams,
  editMutateParams: getTaxDeductionCardParams
})

const getEmploymentParams = (params: { wageEarnerId: number, id: number }, model: EmploymentModel) => ({
  ...params,
  id: model?.wageEarnerId ?? params.wageEarnerId,
  subItem: 'employments',
  subItemId: params.id
})

export const employmentApi = createApi({
  base: 'wage_earners',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getEmploymentParams,
  editMutateParams: getEmploymentParams
})

const getEmploymentSalaryTypeParams = (params: { wageEarnerId: number, id: number }, model: EmploymentSalaryTypeModel) => ({
  ...params,
  id: params.wageEarnerId,
  subItem: 'employments',
  subItemId: params.id,
  subItemAction: 'salary_types',
  salaryTypeId: model?.salaryTypeId
})

export const employmentSalaryTypeApi = createApi({
  base: 'wage_earners',
  extraUrlTemplate: '{/salaryTypeId}',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getEmploymentSalaryTypeParams,
  editMutateParams: getEmploymentSalaryTypeParams
})
