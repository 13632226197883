import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import customerActions from '../actions/customerActions'
import invoiceActions from '../actions/invoiceActions'
import {
  chargeInvoice,
  createConsolidateInvoice,
  createCreditInvoice,
  createInterestInvoices,
  createInvoice,
  createInvoiceAccounting,
  createInvoiceFile,
  createReminderInvoices,
  deleteInvoice,
  deleteInvoiceAccounting,
  deleteInvoiceFile,
  doInvoicePatchAction,
  fetchInterestInvoicing,
  fetchInvoiceAccountings,
  fetchInvoiceFiles,
  fetchInvoiceFormSettings,
  fetchInvoices,
  importInvoicePaymentsFromFile,
  normalizeInvoices,
  sendInvoicePdf,
  updateInvoice,
  updateInvoiceAccounting,
  updateInvoiceFormSetting
} from '../api/invoiceApi'
import { actionTypes } from '../constants'
import { getMemoSagas } from '../helpers/generalSagas'
import { addErrorNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubFilesSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleCustomerApiResponse } from './customerSaga'

const handleInvoiceApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, tableOptions, subInvoices }) {
    yield put(mainAction(data))
    if(tableOptions && tableIdentifier != null && tableOptions.orderBy != null) {
      yield put(invoiceActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    if(subInvoices) {
      yield put(invoiceActions.fetchSuccess(subInvoices))
    }
    return data
  }

const watchOnInvoiceSockets = createSocketWatcherWithApiHandlerAndNormalizer('invoice', invoiceActions, handleInvoiceApiResponse, normalizeInvoices)

const watchOnAccountingSockets = createSocketWatcher('invoiceAccounting', {
  created: invoiceActions.accountings.createSuccess,
  updated: invoiceActions.accountings.updateSuccess
})

const invoiceFetchFlow = fetchFlow({
  fetchApi: fetchInvoices,
  actions: invoiceActions,
  base: 'invoices',
  errorMsg: 'laskujen',
  getApiResponseHandler: data => handleInvoiceApiResponse(invoiceActions.fetchSuccess, data.tableIdentifier)
})

const invoiceUpdateFlow = updateFlow(updateInvoice, invoiceActions, 'Lasku', 'Laskun', handleInvoiceApiResponse(invoiceActions.updateSuccess))
const invoiceCreateFlow = createFlow(createInvoice, invoiceActions, 'Lasku', 'Laskun', handleInvoiceApiResponse(invoiceActions.createSuccess))
const invoiceDeleteFlow = deleteFlow({
  deleteApi: deleteInvoice,
  actions: invoiceActions,
  singular: 'Lasku',
  errorMsg: 'Laskun',
  base: 'invoices'
})
const invoiceSearchFlow = searchFlow(fetchInvoices, invoiceActions, 'Laskujen', function* (data, searchTerm) {
  const invoices = yield handleInvoiceApiResponse(invoiceActions.fetchSuccess, false)(data)
  yield put(invoiceActions.searchSuccess(invoices, searchTerm))
})

function* invoiceChargeFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(invoiceActions.chargeInvoiceStart(record))
    const apiResponse = yield call(chargeInvoice, record, data)
    yield handleInvoiceApiResponse(invoiceActions.updateSuccess)(apiResponse)
    yield put(invoiceActions.chargeInvoiceSuccess(apiResponse.data))
    addSuccessNotification('Lasku laskutettu')
    yield call(resolve, apiResponse.data)
  } catch(err) {
    yield put(invoiceActions.chargeInvoiceError(record))
    yield * genericSagaErrorHandler(err, 'Virhe laskun laskuttamisessa', reject)
  }
}

function* paymentImportFlow({ file }) {
  try {
    const { payments, errors } = yield call(importInvoicePaymentsFromFile, file)
    yield put(invoiceActions.accountings.fetchSuccess(payments))
    addSuccessNotification(`${payments.length} viitemaksua lisätty`)
    if(errors.length) {
      addErrorNotification(`${errors.length} virhettä viitemaksuissa`)
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe viitemaksujen käsittelyssä')
  }
}

const fileFlows = getSubFilesSagas({
  mainActions: invoiceActions,
  fetchApi: fetchInvoiceFiles,
  createApi: createInvoiceFile,
  deleteApi: deleteInvoiceFile,
  mainIdFieldName: 'invoiceId',
  mainReduxName: 'invoices',
  messages: {
    singular: 'Laskun tiedosto',
    accusative: 'Laskun tiedoston',
    fetchError: 'Virhe laskun tiedostojen noutamisessa',
    deleteSuccess: 'Laskun tiedostolinkki poistettu',
    deleteError: 'Virhe laskun tiedostolinkin poistamisessa'
  }
})

const invoiceAccountingsFetchFlow = fetchFlow({
  fetchApi: fetchInvoiceAccountings,
  actions: invoiceActions.accountings,
  errorMsg: 'laskun suoritusten'
})

const invoiceAccountingCreateFlow = createFlow(createInvoiceAccounting, invoiceActions.accountings, 'Maksu', 'Maksun')
const invoiceAccountingUpdateFlow = updateFlow(updateInvoiceAccounting, invoiceActions.accountings, (data, response) => `${data.notificationText || 'Maksu'} päivitetty`, 'Maksun')
const invoiceAccountingDeleteFlow = deleteFlow({
  deleteApi: deleteInvoiceAccounting,
  actions: invoiceActions.accountings,
  singular: 'Laskun maksu',
  errorMsg: err => `Virhe laskun maksun poistamisessa${err.json ? `: ${err.json.message}` : ''}`,
  base: 'invoices.accountings'
})

const reminderInvoiceCreateFlow = createFlow(createReminderInvoices, invoiceActions, 'Maksumuistutukset', 'Maksumuistutusten', handleInvoiceApiResponse(invoiceActions.fetchSuccess))

function* downloadPdfFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const { invoiceId } = record
  try {
    if(data.force || !(yield select(state => state.invoices.pdf)).base64) {
      yield put(invoiceActions.downloadPdfStart())
      const pdf = yield call(sendInvoicePdf, invoiceId)
      yield put(invoiceActions.downloadPdfSuccess(pdf))
      yield call(resolve, pdf)
    }
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe laskun PDF:n noutamisessa', reject)
  }
}

function* creditInvoiceCreateFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const apiResponse = yield call(createCreditInvoice, record, data)
    // NOTE: handling createSuccess is not enough since the invoice is updated during the process (e.g. open balance to 0 and external invoices)
    yield handleInvoiceApiResponse(invoiceActions.updateSuccess)(apiResponse)
    yield call(resolve, apiResponse.data)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe hyvityslaskun luomisessa', reject)
  }
}

const createInvoiceActionFlow = (apiAction, message = 'Toiminto') => function* ({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(record)
  try {
    const responseBody = record
    const urlExpand = data
    const response = yield call(apiAction, responseBody, urlExpand)
    const invoice = yield handleInvoiceApiResponse(invoiceActions.createSuccess)(response)
    addSuccessNotification(`${message} onnistui`)
    yield call(resolve, invoice)
  } catch(err) {
    yield * genericSagaErrorHandler(err, `${message} epäonnistui`, reject)
  }
}

const invoicePatchActionFlow = createInvoiceActionFlow(doInvoicePatchAction)

const interestInvoicingFetchFlow = fetchFlow({
  fetchApi: fetchInterestInvoicing,
  actions: invoiceActions.interestInvoicing,
  base: 'invoices.interestInvoicing',
  errorMsg: 'Korkolaskujen',
  apiResponseHandler: handleInterestInvoicingApiResponse
})

const interestInvoiceCreateFlow = createFlow(createInterestInvoices, invoiceActions, 'Korkolaskut', 'Korkolaskujen', handleCreateInterestInvoiceApiResponse)

const consolidateInvoiceCreateFlow = createFlow(createConsolidateInvoice, invoiceActions, 'Koontilasku', 'Koontilaskun', handleInvoiceApiResponse(invoiceActions.createSuccess))

function* handleInterestInvoicingApiResponse({ data, invoices, customers }) {
  yield handleInvoiceApiResponse(invoiceActions.fetchSuccess)(invoices)
  yield handleCustomerApiResponse(customerActions.fetchSuccess)(customers)
  yield put(invoiceActions.interestInvoicing.fetchSuccess(data, { replace: true }))
}

function* handleCreateInterestInvoiceApiResponse(invoices) {
  yield handleInvoiceApiResponse(invoiceActions.fetchSuccess)(invoices)
  yield put(invoiceActions.interestInvoicing.fetchSuccess([], { replace: true }))
}

const memoSagas = getMemoSagas({
  actions: invoiceActions,
  baseName: 'invoices',
  socketName: 'invoice',
  titleGenetive: 'laskun'
})

const formSettingsFetchFlow = fetchFlow({
  fetchApi: fetchInvoiceFormSettings,
  actions: invoiceActions.formSettings,
  errorMsg: 'laskun lomakeasetusten'
})

const formSettingUpdateFlow = updateFlow(updateInvoiceFormSetting, invoiceActions.formSettings, 'Laskun lomakeasetukset', 'Laskun lomakeasetusten')

export default function* invoiceSaga() {
  yield takeEvery(invoiceActions.actionTypes.fetchRequest, invoiceFetchFlow)
  yield takeEvery(invoiceActions.actionTypes.updateRequest, invoiceUpdateFlow)
  yield takeEvery(invoiceActions.actionTypes.createRequest, invoiceCreateFlow)
  yield takeEvery(invoiceActions.actionTypes.deleteRequest, invoiceDeleteFlow)
  yield takeEvery(invoiceActions.actionTypes.searchRequest, invoiceSearchFlow)
  yield takeEvery(actionTypes.CHARGE_INVOICE_REQUEST, invoiceChargeFlow)

  yield takeLatest(actionTypes.INVOICE_PAYMENTS_IMPORT_REQUEST, paymentImportFlow)

  yield takeLatest(invoiceActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(invoiceActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(invoiceActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(invoiceActions.accountings.actionTypes.fetchRequest, invoiceAccountingsFetchFlow)
  yield takeEvery(invoiceActions.accountings.actionTypes.createRequest, invoiceAccountingCreateFlow)
  yield takeEvery(invoiceActions.accountings.actionTypes.updateRequest, invoiceAccountingUpdateFlow)
  yield takeLatest(invoiceActions.accountings.actionTypes.deleteRequest, invoiceAccountingDeleteFlow)

  yield takeEvery(actionTypes.REMINDER_INVOICES_CREATE_REQUEST, reminderInvoiceCreateFlow)

  yield takeEvery(invoiceActions.interestInvoicing.actionTypes.fetchRequest, interestInvoicingFetchFlow)
  yield takeEvery(invoiceActions.interestInvoicing.actionTypes.createRequest, interestInvoiceCreateFlow)

  yield takeLatest(actionTypes.DOWNLOAD_PDF_INVOICE_REQUEST, downloadPdfFlow)
  yield takeEvery(actionTypes.INVOICE_PATCH_ACTION_REQUEST, invoicePatchActionFlow)

  yield takeLatest(actionTypes.CREATE_CREDIT_INVOICE_REQUEST, creditInvoiceCreateFlow)

  yield takeEvery(actionTypes.CONSOLIDATED_INVOICE_CREATE_REQUEST, consolidateInvoiceCreateFlow)

  yield takeLatest(invoiceActions.formSettings.actionTypes.fetchRequest, formSettingsFetchFlow)
  yield takeEvery(invoiceActions.formSettings.actionTypes.updateRequest, formSettingUpdateFlow)

  yield all([
    memoSagas(),
    watchOnInvoiceSockets(),
    watchOnAccountingSockets()
  ])
}
