import { fileRelationTables } from '@evelia/common/constants'
import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import authorActions from '../actions/authorActions'
import contactActions from '../actions/contactActions'
import costCentreActions from '../actions/costCentreActions'
import customerActions from '../actions/customerActions'
import denominationActions from '../actions/denominationActions'
import employeeActions from '../actions/employeeActions'
import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import invoiceActions from '../actions/invoiceActions'
import projectActions from '../actions/projectActions'
import targetActions from '../actions/targetActions'
import workActions from '../actions/workActions'
import { normalizeInboundInvoices } from '../api/inboundInvoiceApi'
import {
  createProject,
  createProjectAdditionalPerson,
  createProjectContact,
  createProjectEmployee,
  createProjectFile,
  createRTVReport,
  deleteProject,
  deleteProjectAdditionalPerson,
  deleteProjectContact,
  deleteProjectFile,
  doPostAction,
  fetchProjectAdditionalPersons,
  fetchProjectContacts,
  fetchProjectEmployees,
  fetchProjectFiles,
  fetchProjects,
  fetchProjectSubItem,
  generateProjectReport,
  normalizeProjectResponse,
  projectDenominationBudgetApi,
  projectExtraBudgetApi,
  projectExtraExpenseApi,
  updateProject,
  updateProjectAdditionalPerson,
  updateProjectContact,
  updateProjectEmployee
} from '../api/projectApi'
import { normalizeWorkList } from '../api/workApi'
import { actionTypes, projectApiActions, projectSubItems } from '../constants'
import { defaultEmbeddedNormalizer } from '../helpers/apiHelpers'
import { getMemoSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcher,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  generateReportFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getSubFilesSagas,
  getSubSagas,
  searchFlow,
  updateFlow
} from '../helpers/sagaHelpers'
import { handleInboundInvoiceApiResponse } from './inboundInvoiceSaga'
import { handleWorkApiResponse } from './workSaga'

const handleProjectApiResponse = (mainAction, tableIdentifier) =>
  function* ({
    data,
    instalments,
    targets,
    customers,
    contacts,
    supervisors,
    costCentres,
    mainProjects,
    extraBudgets,
    authors,
    tableOptions,
    extraInfo,
    ...props
  }) {
    yield put(mainAction(data))
    yield handleWorkApiResponse(workActions.fetchSuccess)(instalments)
    yield put(targetActions.fetchSuccess(targets))
    yield put(customerActions.fetchSuccess(customers))
    yield put(employeeActions.fetchSuccess(supervisors))
    yield put(contactActions.fetchSuccess(contacts))
    yield put(costCentreActions.fetchSuccess(costCentres))

    yield put(projectActions.projectExtraBudgets.fetchSuccess(extraBudgets))
    yield put(projectActions.fetchSuccess(mainProjects))
    yield put(authorActions.fetchSuccess(authors))
    yield put(projectActions.fetchExtraInfoSuccess(extraInfo))
    if(tableOptions?.orderBy != null && tableIdentifier) {
      yield put(projectActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleProjectExtraBudgetApiResponse = mainAction =>
  function* ({ data, authors }) {
    yield put(mainAction(data))
    yield put(authorActions.fetchSuccess(authors))
    return data
  }

const handleProjectDenominationBudgetApiResponse = mainAction =>
  function* ({ data, denominations, authors }) {
    yield put(mainAction(data))
    yield put(denominationActions.fetchSuccess(denominations))
    yield put(authorActions.fetchSuccess(authors))
    return data
  }

const watchOnProjectSockets = createSocketWatcherWithApiHandlerAndNormalizer('project', projectActions, handleProjectApiResponse, normalizeProjectResponse)

const watchOnProjectExtraBudgetSockets = createSocketWatcherWithApiHandlerAndNormalizer('projectExtraBudget', projectActions.projectExtraBudgets, handleProjectExtraBudgetApiResponse, defaultEmbeddedNormalizer)

const watchOnProjectExtraExpenseSockets = createSocketWatcherWithApiHandlerAndNormalizer('projectExtraExpense', projectActions.projectExtraExpenses, handleProjectExtraBudgetApiResponse, defaultEmbeddedNormalizer)

const watchOnProjectFileSockets = createSocketWatcher('projectFile', {
  created: projectActions.files.createSuccess,
  updated: projectActions.files.updateSuccess
})

const projectFetchFlow = fetchFlow({
  fetchApi: fetchProjects,
  actions: projectActions,
  base: 'projects',
  errorMsg: 'Projektien',
  getApiResponseHandler: data => handleProjectApiResponse(projectActions.fetchSuccess, data.tableIdentifier)
})

const projectUpdateFlow = updateFlow(updateProject, projectActions, 'Projekti', 'Projektin', handleProjectApiResponse(projectActions.updateSuccess))
const projectCreateFlow = createFlow(createProject, projectActions, 'Projekti', 'Projektin', handleProjectApiResponse(projectActions.createSuccess))
const projectDeleteFlow = deleteFlow({
  deleteApi: deleteProject,
  actions: projectActions,
  singular: 'Projekti',
  errorMsg: 'Projektin',
  base: 'projects'
})

function* decorateSearchTerm(searchTerm) {
  return yield call(fetchProjects, { q: searchTerm })
}
const projectSearchFlow = searchFlow(decorateSearchTerm, projectActions, 'Projektien', function* (data, searchQuery) {
  const projects = yield handleProjectApiResponse(projectActions.fetchSuccess)(data)
  yield put(projectActions.searchSuccess(projects, searchQuery.q))
  return projects
})

const projectFileSagaMessages = {
  singular: 'Projektin tiedosto',
  accusative: 'Projektin tiedoston',
  fetchError: 'Virhe projektin tiedostojen noutamisessa',
  deleteSuccess: 'Projektin tiedostolinkki poistettu',
  deleteError: 'Virhe projektin tiedostolinkin poistamisessa'
}

const projectTreeRelationSpecs = {
  endpointName: 'getProjectFiles',
  relationType: fileRelationTables.PROJECT
}

const fileFlows = getSubFilesSagas({
  mainActions: projectActions,
  fetchApi: fetchProjectFiles,
  createApi: createProjectFile,
  deleteApi: deleteProjectFile,
  mainIdFieldName: 'projectId',
  mainReduxName: 'projects',
  messages: projectFileSagaMessages,
  treeRelationSpecs: projectTreeRelationSpecs
})

const {
  subFetchFlow: projectAdditionalPersonsFetchFlow,
  subCreateFlow: projectAdditionalPersonsCreateFlow,
  subUpdateFlow: projectAdditionalPersonsUpdateFlow,
  subDeleteFlow: projectAdditionalPersonsDeleteFlow
} = getSubSagas({
  mainActions: projectActions,
  mainReduxName: 'projects',
  subReduxName: 'additionalPersons'
}, {
  fetchApi: fetchProjectAdditionalPersons,
  createApi: createProjectAdditionalPerson,
  updateApi: updateProjectAdditionalPerson,
  deleteApi: deleteProjectAdditionalPerson
}, {
  singular: 'Projektin alihankkija',
  accusative: 'Projektin alihankkijan',
  fetchError: 'Virhe projektin alihankkijoiden noutamisessa',
  deleteSuccess: 'Projektin alihankkija poistettu',
  deleteError: 'Virhe projektin alihankkijan poistamisessa'
})

const {
  subFetchFlow: projectContactsFetchFlow,
  subCreateFlow: projectContactsCreateFlow,
  subUpdateFlow: projectContactsUpdateFlow,
  subDeleteFlow: projectContactsDeleteFlow
} = getSubSagas({
  mainActions: projectActions,
  mainReduxName: 'projects',
  subReduxName: 'contacts'
}, {
  fetchApi: fetchProjectContacts,
  createApi: createProjectContact,
  updateApi: updateProjectContact,
  deleteApi: deleteProjectContact
}, {
  singular: 'Projektin henkilö',
  accusative: 'Projektin henkilön',
  fetchError: 'Virhe projektin henkilöiden noutamisessa',
  deleteSuccess: 'Projektin henkilö poistettu',
  deleteError: 'Virhe projektin henkilöiden poistamisessa'
})

const {
  subFetchFlow: projectEmployeesFetchFlow,
  subCreateFlow: projectEmployeeCreateFlow,
  subUpdateFlow: projectEmployeeUpdateFlow
} = getSubSagas({
  mainActions: projectActions,
  mainReduxName: 'projects',
  subReduxName: 'employees'
}, {
  fetchApi: fetchProjectEmployees,
  updateApi: updateProjectEmployee,
  createApi: createProjectEmployee
}, {
  singular: 'Projektin työntekijä',
  accusative: 'Projektin työntekijän',
  fetchError: 'Virhe projektin työntekijöiden noutamisessa'
})

function* fetchProjectWorkFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(workActions.fetchStart())
    const apiResponse = yield call(fetchProjectSubItem, data)
    const normalizedResponse = normalizeWorkList(apiResponse)
    yield handleWorkApiResponse(workActions.fetchSuccess)(normalizedResponse)
    yield call(resolve, normalizedResponse)
  } catch(err) {
    yield put(workActions.fetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe projektin töiden noutamisessa', reject)
  }
}

function* fetchProjectInvoicesFlow({ data = {} }) {
  try {
    yield put(invoiceActions.fetchStart())
    const { projectId } = data
    const { records: invoices } = yield call(fetchProjectSubItem, data)
    const existingProjectInvoices = yield select(state => state.projects.invoices.records)
    const projectInvoices = invoices.map((invoice, index) => ({
      id: existingProjectInvoices.length + index,
      projectId,
      invoiceId: invoice.id
    }))
    yield put(invoiceActions.fetchSuccess(invoices))
    yield put(projectActions.invoices.fetchSuccess(projectInvoices))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe laskujen noutamisessa')
  }
}

function* fetchProjectInboundInvoicesFlow({ data = {} }) {
  try {
    const { projectId } = data
    yield put(inboundInvoiceActions.fetchStart())
    const apiResponse = yield call(fetchProjectSubItem, data)
    const response = normalizeInboundInvoices(apiResponse)
    const inboundInvoices = yield handleInboundInvoiceApiResponse(inboundInvoiceActions.fetchSuccess, null)(response)

    const existingProjectInboundInvoices = yield select(state => state.projects.inboundInvoices.records)
    const projectInboundInvoices = inboundInvoices.map((inboundInvoice, index) => ({
      id: existingProjectInboundInvoices.length + index,
      projectId,
      inboundInvoiceId: inboundInvoice.id
    }))
    yield put(inboundInvoiceActions.fetchSuccess(inboundInvoices))
    yield put(projectActions.inboundInvoices.fetchSuccess(projectInboundInvoices))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe ostolaskujen noutamisessa')
  }
}

const fetchProjectBudgetFlow = fetchFlow({
  fetchApi: fetchProjectSubItem,
  actions: {
    fetchStart: projectActions.budgetFetchStart,
    fetchSuccess: projectActions.budgetFetchSuccess,
    fetchError: projectActions.budgetFetchError
  },
  base: 'projects.budgets',
  idField: '_id',
  errorMsg: 'projektin budjetin',
  updateLastFetched: true
})

const fetchProjectBudgetRecursiveFlow = fetchFlow({
  fetchApi: fetchProjectSubItem,
  actions: {
    fetchStart: projectActions.budgetRecursiveFetchStart,
    fetchSuccess: projectActions.budgetRecursiveFetchSuccess,
    fetchError: projectActions.budgetRecursiveFetchError
  },
  base: 'projects.budgetsRecursive',
  idField: '_id',
  errorMsg: 'projektin budjetin',
  updateLastFetched: true
})

const fetchProjectBudgetByDenominationFlow = fetchFlow({
  fetchApi: fetchProjectSubItem,
  actions: {
    fetchStart: projectActions.budgetByDenominationFetchStart,
    fetchSuccess: projectActions.budgetByDenominationFetchSuccess,
    fetchError: projectActions.budgetByDenominationFetchError
  },
  base: 'projects.budgetsByDenomination',
  idField: '_id',
  errorMsg: 'projektin litterabudjetin',
  updateLastFetched: true
})

const getProjectFetchSubItemActionFlow = data => {
  switch(data.subItem) {
    case projectSubItems.PROJECTS_WORK:
      return fetchProjectWorkFlow
    case projectSubItems.PROJECTS_INVOICES:
      return fetchProjectInvoicesFlow
    case projectSubItems.PROJECTS_INBOUND_INVOICES:
      return fetchProjectInboundInvoicesFlow
    case projectSubItems.PROJECTS_BUDGET:
      if(data.subItemType === projectSubItems.PROJECTS_BUDGET_BY_DENOMINATION) {
        return fetchProjectBudgetByDenominationFlow
      }
      if(data.subItemType === projectSubItems.PROJECTS_BUDGET_RECURSIVE) {
        return fetchProjectBudgetRecursiveFlow
      }
      return fetchProjectBudgetFlow
    default:
      return null
  }
}

function* projectFetchSubItemActionFlow({ record, data = {} }) {
  const fetchFlow = getProjectFetchSubItemActionFlow(data)

  if(fetchFlow) {
    yield * fetchFlow({ record, data })
  } else {
    console.error('Invalid project fetch action')
  }
}

const projectApiActionFlows = {
  [projectApiActions.INSTALMENTS]: createProjectInstalmentFlow,
  [projectApiActions.INSTALMENT_TEMPLATES]: createProjectInstalmentFlow
}

function* projectApiActionFlow({ record, data = {} }) {
  const fetchFlow = projectApiActionFlows[data.action]
  if(fetchFlow) {
    yield * fetchFlow({ record, data })
  } else {
    console.error('Invalid project fetch action')
  }
}

function* createProjectInstalmentFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const responseBody = record
    const urlExpand = data
    const response = yield call(doPostAction, responseBody, urlExpand)
    yield handleProjectApiResponse(projectActions.updateSuccess)(response)
    addSuccessNotification('Maksutaulukon luonti onnistui')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Maksutaulukon luonti epäonnistui', reject)
  }
}

function* projectsCreateRTVReportFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const { targetMonth } = data
  try {
    const response = yield call(createRTVReport, { targetMonth })
    if(response?.length) {
      addSuccessNotification('Raportti luotu')
    } else {
      addSuccessNotification('Ei raportoitavaa')
    }
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe raportin luonnissa', reject)
  }
}

const projectExtraExpensesFetchFlow = fetchFlow({
  fetchApi: projectExtraExpenseApi.fetch,
  actions: projectActions.projectExtraExpenses,
  base: 'projects.projectExtraExpenses',
  errorMsg: 'Lisäkulujen',
  apiResponseHandler: handleProjectExtraBudgetApiResponse(projectActions.projectExtraExpenses.fetchSuccess)
})
const projectExtraExpensesCreateFlow = createFlow(projectExtraExpenseApi.create, projectActions.projectExtraExpenses, 'Lisäkulu', 'Lisäkulujen', handleProjectExtraBudgetApiResponse(projectActions.projectExtraExpenses.createSuccess))

const projectExtraBudgetsFetchFlow = fetchFlow({
  fetchApi: projectExtraBudgetApi.fetch,
  actions: projectActions.projectExtraBudgets,
  base: 'projects.projectExtraBudgets',
  errorMsg: 'Lisäbudjettien',
  apiResponseHandler: handleProjectExtraBudgetApiResponse(projectActions.projectExtraBudgets.fetchSuccess)
})
const projectExtraBudgetsCreateFlow = createFlow(projectExtraBudgetApi.create, projectActions.projectExtraBudgets, 'Lisäbudjetti', 'Lisäbudjettien', handleProjectExtraBudgetApiResponse(projectActions.projectExtraBudgets.createSuccess))

const projectDenominationBudgetsFetchFlow = fetchFlow({
  fetchApi: projectDenominationBudgetApi.fetch,
  actions: projectActions.projectDenominationBudgets,
  base: 'projects.projectDenominationBudgets',
  errorMsg: 'Litterabudjettien',
  apiResponseHandler: handleProjectDenominationBudgetApiResponse(projectActions.projectDenominationBudgets.fetchSuccess)
})
const projectDenominationBudgetsCreateFlow = createFlow(projectDenominationBudgetApi.create, projectActions.projectDenominationBudgets, 'Litterabudjetti', 'Litterabudjettien', handleProjectDenominationBudgetApiResponse(projectActions.projectDenominationBudgets.createSuccess))

const memoSagas = getMemoSagas({
  actions: projectActions,
  baseName: 'projects',
  socketName: 'project',
  titleGenetive: 'projektin'
})

const generateProjectReportFlow = generateReportFlow(generateProjectReport)

export default function* projectSaga() {
  yield takeLatest(projectActions.actionTypes.fetchRequest, projectFetchFlow)
  yield takeEvery(projectActions.actionTypes.updateRequest, projectUpdateFlow)
  yield takeEvery(projectActions.actionTypes.createRequest, projectCreateFlow)
  yield takeEvery(projectActions.actionTypes.deleteRequest, projectDeleteFlow)
  yield takeLatest(projectActions.actionTypes.searchRequest, projectSearchFlow)

  yield takeLatest(projectActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(projectActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(projectActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeLatest(projectActions.additionalPersons.actionTypes.fetchRequest, projectAdditionalPersonsFetchFlow)
  yield takeLatest(projectActions.additionalPersons.actionTypes.updateRequest, projectAdditionalPersonsUpdateFlow)
  yield takeLatest(projectActions.additionalPersons.actionTypes.createRequest, projectAdditionalPersonsCreateFlow)
  yield takeLatest(projectActions.additionalPersons.actionTypes.deleteRequest, projectAdditionalPersonsDeleteFlow)

  yield takeLatest(projectActions.contacts.actionTypes.fetchRequest, projectContactsFetchFlow)
  yield takeLatest(projectActions.contacts.actionTypes.updateRequest, projectContactsUpdateFlow)
  yield takeLatest(projectActions.contacts.actionTypes.createRequest, projectContactsCreateFlow)
  yield takeLatest(projectActions.contacts.actionTypes.deleteRequest, projectContactsDeleteFlow)

  yield takeLatest(projectActions.employees.actionTypes.fetchRequest, projectEmployeesFetchFlow)
  yield takeLatest(projectActions.employees.actionTypes.updateRequest, projectEmployeeUpdateFlow)
  yield takeLatest(projectActions.employees.actionTypes.createRequest, projectEmployeeCreateFlow)

  yield takeLatest(projectActions.projectExtraExpenses.actionTypes.fetchRequest, projectExtraExpensesFetchFlow)
  yield takeLatest(projectActions.projectExtraExpenses.actionTypes.createRequest, projectExtraExpensesCreateFlow)

  yield takeLatest(projectActions.projectExtraBudgets.actionTypes.fetchRequest, projectExtraBudgetsFetchFlow)
  yield takeLatest(projectActions.projectExtraBudgets.actionTypes.createRequest, projectExtraBudgetsCreateFlow)

  yield takeLatest(projectActions.projectDenominationBudgets.actionTypes.fetchRequest, projectDenominationBudgetsFetchFlow)
  yield takeLatest(projectActions.projectDenominationBudgets.actionTypes.createRequest, projectDenominationBudgetsCreateFlow)

  yield takeEvery(actionTypes.PROJECTS_FETCH_ACTION_REQUEST, projectFetchSubItemActionFlow)
  yield takeEvery(actionTypes.PROJECT_API_ACTION_REQUEST, projectApiActionFlow)

  yield takeLatest(actionTypes.PROJECTS_CREATE_RTV_REPORT, projectsCreateRTVReportFlow)
  yield takeLatest(actionTypes.PROJECT_GENERATE_REPORT_REQUEST, generateProjectReportFlow)

  yield all([
    memoSagas(),
    watchOnProjectSockets(),
    watchOnProjectFileSockets(),
    watchOnProjectExtraBudgetSockets(),
    watchOnProjectExtraExpenseSockets()
  ])
}
