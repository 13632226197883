import type {
  AnnotationFile,
  AnnotationFilePage,
  AnnotationFilePageCustomState,
  BaseElement,
  FileModel,
  NullableFields,
  OfferAnnotationPostRowExtraData,
  OfferAnnotationType,
  OfferPostRowModel,
  ShapeElement
} from '@evelia/common/types'
import type { TagDescription } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'
import queryString from 'query-string'

import offerActions from '../../actions/offerActions'
import packetActions from '../../actions/packetActions'
import productActions from '../../actions/productActions'
import { filesAdded } from '../../slices/filesSlice'
import { getBaseQuery, transformErrorResponse } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'
import type { ApiRecordResponse, ApiResponse } from './types/api'

type OfferFile = {
  id: number
  offerId: number
  systemTag: number
  description: string | null
  _embedded: {
    file: FileModel
  }
}

type OfferAnnotationFilesResponse = {
  records: AnnotationFile[]
  _embedded: {
    offerFiles: OfferFile[]
  }
}

type OfferAnnotationFileResponse = {
  record: AnnotationFile
} & Pick<OfferAnnotationFilesResponse, '_embedded'>

const dispatchOfferFiles = (dispatch, _offerFiles: OfferFile[]) => {
  const { files, offerFiles } = _offerFiles.reduce((prev, { _embedded, ...offerFile }) => ({
    files: prev.files.concat(_embedded.file),
    offerFiles: prev.offerFiles.concat(offerFile)
  }), { files: [] as OfferFile['_embedded']['file'][], offerFiles: [] as Omit<OfferFile, '_embedded'>[] })
  dispatch(offerActions.files.fetchSuccess(offerFiles))
  dispatch(filesAdded(files))
}

const invalidateTypes = (response: ApiRecordResponse<AnnotationFilePage> | OfferAnnotationFileResponse | undefined, __error: unknown, args: { type: string | null }): TagDescription<'types'>[] => {
  if(response?.record.type !== args.type) {
    return ['types']
  }
  return []
}

export type UpdateAnnotationFilePayload = Omit<AnnotationFile, 'offerFileId' | 'updatedAt' | 'createdAt' | 'pageCount'> & { description: string | null }
export type CreateAnnotationFilePayload = Omit<UpdateAnnotationFilePayload, 'id'> & { fileId: number, pageCount: number }
export type CreateAnnotationFilePagePayload = Omit<NullableFields<AnnotationFilePage, 'customStateId'>, 'isCompleted' | 'updatedAt' | 'createdAt' | 'shapeCount'> & { offerId: number }
export type UpdateAnnotationFilePagePayload = CreateAnnotationFilePagePayload
export type UpdateAnnotationShapePayload = Omit<ShapeElement, 'zIndex' | 'offerPostRowId' | 'createdAt' | 'updatedAt' | 'normHourSum'> & { offerId: number }
export type CreateAnnotationShapePayload = Omit<UpdateAnnotationShapePayload, 'id'> & { offerPostRowId: BaseElement['offerPostRowId'], offerPostRowExtraData?: OfferAnnotationPostRowExtraData | null }

const baseApi = createApi({
  reducerPath: 'offerAnnotationApi',
  baseQuery: getBaseQuery('offers/'),
  tagTypes: ['types'],
  endpoints: builder => ({
    getAnnotationFiles: builder.query<OfferAnnotationFilesResponse, { offerId: number }>({
      query: ({ offerId }) => `${offerId}/annotations`,
      transformErrorResponse,
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelupohjien haussa`,
          returnValue: true
        })
        if(result?.data._embedded.offerFiles) {
          dispatchOfferFiles(dispatch, result.data._embedded.offerFiles)
        }
      }
    }),
    createAnnotationFile: builder.mutation<OfferAnnotationFileResponse, CreateAnnotationFilePayload>({
      query: ({ offerId, ...body }) => ({
        method: 'POST',
        url: `${offerId}/annotations`,
        body
      }),
      transformErrorResponse,
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelupohjan luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationFiles', { offerId: result.data.record.offerId }, data => {
            if(data) {
              data.records.push(result.data.record)
            }
          }))
          dispatchOfferFiles(dispatch, result.data._embedded.offerFiles)
        }
      },
      invalidatesTags: invalidateTypes
    }),
    updateAnnotationFile: builder.mutation<OfferAnnotationFileResponse, UpdateAnnotationFilePayload>({
      query: ({ offerId, id, ...body }) => ({
        method: 'PUT',
        url: `${offerId}/annotations/${id}`,
        body
      }),
      transformErrorResponse,
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelupohjan luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatchOfferFiles(dispatch, result.data._embedded.offerFiles)
        }
      },
      invalidatesTags: invalidateTypes
    }),
    getAnnotationPageCustomStates: builder.query<ApiResponse<AnnotationFilePageCustomState>, void>({
      query: constant('annotation_custom_states'),
      transformErrorResponse
    }),
    createAnnotationPageCustomState: builder.mutation<ApiRecordResponse<AnnotationFilePageCustomState>, Omit<AnnotationFilePageCustomState, 'id' | 'createdAt' | 'updatedAt'>>({
      query: body => ({
        method: 'POST',
        url: 'annotation_custom_states',
        body
      }),
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe sivutietojen luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationPageCustomStates', undefined, data => {
            if(data) {
              data.records.push(result.data.record)
            }
          })
          )
        }
      }
    }),
    updateAnnotationPageCustomState: builder.mutation<ApiRecordResponse<AnnotationFilePageCustomState>, Omit<AnnotationFilePageCustomState, 'createdAt' | 'updatedAt'>>({
      query: body => {
        const { id, ...data } = body
        return ({
          method: 'PUT',
          url: `annotation_custom_states/${id}`,
          body: data
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe sivutietojen luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationPageCustomStates', undefined, data => {
            if(data) {
              const index = data.records.findIndex(({ id }) => id === result.data.record.id)
              if(index > -1) {
                data.records[index] = result.data.record
              }
            }
          }))
        }
      }
    }),
    removeAnnotationPageCustomState: builder.mutation<void, number>({
      query: id => ({
        method: 'DELETE',
        url: `annotation_custom_states/${id}`
      }),
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe sivutietojen luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationPageCustomStates', undefined, data => {
            if(data) {
              data.records = data.records.filter(({ id }) => id !== args)
            }
          }))
        }
      }
    }),
    getAnnotationPages: builder.query<ApiResponse<AnnotationFilePage>, { offerId: number, offerAnnotationFileId: number }>({
      query: ({ offerId, offerAnnotationFileId }) => {
        return `${offerId}/annotations/${offerAnnotationFileId}/pages/`
      },
      transformErrorResponse,
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe sivutietojen haussa`
      })
    }),
    getAnnotationPage: builder.query<ApiRecordResponse<AnnotationFilePage>, { offerId: number, offerAnnotationFileId: number, page: number }>({
      query: ({ offerId, offerAnnotationFileId, page }) => {
        return `${offerId}/annotations/${offerAnnotationFileId}/pages/${page}`
      },
      transformErrorResponse,
      onQueryStarted: async(__args, { queryFulfilled, dispatch }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe sivutietojen haussa`,
        ignoreErrors: ({ error }) => {
          return error?.status === 400 && error?.message === 'Riviä ei löytynyt'
        }
      })
    }),
    createAnnotationPage: builder.mutation<ApiRecordResponse<AnnotationFilePage>, CreateAnnotationFilePagePayload>({
      query: ({ offerAnnotationFileId, page, offerId, ...body }) => {
        return ({
          method: 'POST',
          url: `${offerId}/annotations/${offerAnnotationFileId}/pages/${page}`,
          body
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe sivutietojen luonnissa`,
        showValidationErrors: true
      }),
      invalidatesTags: invalidateTypes
    }),
    updateAnnotationPage: builder.mutation<ApiRecordResponse<AnnotationFilePage>, UpdateAnnotationFilePagePayload>({
      query: ({ offerAnnotationFileId, page, offerId, ...body }) => {
        return ({
          method: 'PUT',
          url: `${offerId}/annotations/${offerAnnotationFileId}/pages/${page}`,
          body
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe sivutietojen päivityksessä`,
        showValidationErrors: true
      }),
      invalidatesTags: invalidateTypes
    }),
    getAnnotationShapes: builder.query<ApiResponse<ShapeElement>, { offerId: number, offerAnnotationFileId: number, page?: number }>({
      query: ({ offerId, offerAnnotationFileId, page }) => {
        const query = queryString.stringify({ page })
        return `${offerId}/annotations/${offerAnnotationFileId}/shapes?${query}`
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivien haussa`,
          returnValue: true
        })
        if(result) {
          dispatch(packetActions.fetchSuccess(result.data._embedded.packets ?? []))
          dispatch(packetActions.packetInstallations.fetchSuccess(result.data._embedded.packetInstallations ?? []))
          dispatch(packetActions.installations.fetchSuccess(result.data._embedded.installations ?? []))
          dispatch(productActions.fetchSuccess(result.data._embedded.products ?? []))
        }
      }
    }),
    getOfferPostAnnotationShapes: builder.query<ApiResponse<ShapeElement>, { offerId: number, offerPostId: number }>({
      query: ({ offerId, offerPostId }) => {
        return `${offerId}/annotations/shapes/${offerPostId}`
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivien haussa`,
          returnValue: true
        })
        if(result) {
          dispatch(packetActions.fetchSuccess(result.data._embedded.packets ?? []))
          dispatch(packetActions.packetInstallations.fetchSuccess(result.data._embedded.packetInstallations ?? []))
          dispatch(packetActions.installations.fetchSuccess(result.data._embedded.installations ?? []))
          dispatch(productActions.fetchSuccess(result.data._embedded.products ?? []))
        }
      }
    }),
    getAnnotationShape: builder.query<ApiRecordResponse<ShapeElement>, { offerId: number, offerAnnotationFileId: number, id: number }>({
      query: ({ offerId, offerAnnotationFileId, id }) => {
        return `${offerId}/annotations/${offerAnnotationFileId}/shapes/${id}`
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivin haussa`,
          returnValue: true
        })
        if(result) {
          dispatch(packetActions.fetchSuccess(result.data._embedded.packets ?? []))
          dispatch(packetActions.packetInstallations.fetchSuccess(result.data._embedded.packetInstallations ?? []))
          dispatch(packetActions.installations.fetchSuccess(result.data._embedded.installations ?? []))
          dispatch(productActions.fetchSuccess(result.data._embedded.products ?? []))
        }
      }
    }),
    createAnnotationShape: builder.mutation<ApiRecordResponse<ShapeElement>, CreateAnnotationShapePayload>({
      query: body => {
        const { offerAnnotationFileId, offerId, ...data } = body
        return ({
          method: 'POST',
          url: `${offerId}/annotations/${offerAnnotationFileId}/shapes`,
          body: data
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivin luonnissa`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationShapes', { offerId: args.offerId, page: result.data.record.page, offerAnnotationFileId: result.data.record.offerAnnotationFileId }, data => {
            if(data) {
              data.records.push(result.data.record)
            }
          }))
          dispatch(packetActions.fetchSuccess(result.data._embedded.packets ?? []))
          dispatch(packetActions.packetInstallations.fetchSuccess(result.data._embedded.packetInstallations ?? []))
          dispatch(packetActions.installations.fetchSuccess(result.data._embedded.installations ?? []))
          dispatch(productActions.fetchSuccess(result.data._embedded.products ?? []))
          if(result.data.record.offerPostRowId) {
            dispatch(baseApi.util.updateQueryData('getUnassignedOfferPostRows', { offerId: args.offerId, offerAnnotationFileId: result.data.record.offerAnnotationFileId, offerPostId: result.data.record.offerPostId }, data => {
              data.records = data.records.filter(({ id }) => id !== result.data.record.offerPostRowId)
            }))
          }
        }
      }
    }),
    updateAnnotationShape: builder.mutation<ApiRecordResponse<ShapeElement>, UpdateAnnotationShapePayload>({
      query: body => {
        const { offerAnnotationFileId, offerId, id, ...data } = body
        return ({
          method: 'PUT',
          url: `${offerId}/annotations/${offerAnnotationFileId}/shapes/${id}`,
          body: data
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivin päivityksessä`,
          returnValue: true,
          showValidationErrors: true
        })
        if(result) {
          dispatch(packetActions.fetchSuccess(result.data._embedded.packets ?? []))
          dispatch(packetActions.packetInstallations.fetchSuccess(result.data._embedded.packetInstallations ?? []))
          dispatch(packetActions.installations.fetchSuccess(result.data._embedded.installations ?? []))
          dispatch(productActions.fetchSuccess(result.data._embedded.products ?? []))
        }
      }
    }),
    deleteAnnotationShape: builder.mutation<void, Pick<ShapeElement, 'id' | 'offerAnnotationFileId' | 'page'> & { offerId: number }>({
      query: body => {
        const { offerAnnotationFileId, offerId, id } = body
        return ({
          method: 'DELETE',
          url: `${offerId}/annotations/${offerAnnotationFileId}/shapes/${id}`
        })
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe massoittelurivin päivityksessä`,
          returnValue: true
        })
        if(result) {
          dispatch(baseApi.util.updateQueryData('getAnnotationShapes', { offerId: args.offerId, page: args.page, offerAnnotationFileId: args.offerAnnotationFileId }, data => {
            data.records = data.records.filter(element => element.id !== args.id && (!('parentElementId' in element) || element.parentElementId !== args.id))
          }))
        }
      }
    }),
    bringShapeToFront: builder.mutation<ApiRecordResponse<ShapeElement>, { offerId: number, offerAnnotationFileId: number, id: number, page: number }>({
      query: ({ offerId, offerAnnotationFileId, id }) => ({
        url: `${offerId}/annotations/${offerAnnotationFileId}/shapes/${id}/bring_to_front`,
        method: 'POST'
      }),
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe massoittelurivin päivityksessä`
      })
    }),
    sendShapeToBack: builder.mutation<ApiRecordResponse<ShapeElement>, { offerId: number, offerAnnotationFileId: number, id: number, page: number }>({
      query: ({ offerId, offerAnnotationFileId, id, page }) => ({
        url: `${offerId}/annotations/${offerAnnotationFileId}/shapes/${id}/send_to_back`,
        method: 'POST'
      }),
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => await basicApiNotification(queryFulfilled, {
        errorMessage: `Virhe massoittelurivin päivityksessä`
      })
    }),
    getOfferAnnotationTypes: builder.query<OfferAnnotationType[], void>({
      query: constant('annotation_types'),
      transformErrorResponse,
      transformResponse: (types: { _id: string, type: string }[]) => {
        return types.map(({ type }) => ({ value: type, text: type }))
      },
      providesTags: ['types']
    }),
    getUnassignedOfferPostRows: builder.query<ApiResponse<OfferPostRowModel>, { offerId: number, offerAnnotationFileId: number, offerPostId: number }>({
      query: ({ offerId, offerAnnotationFileId, offerPostId }) => {
        return `${offerId}/annotations/${offerAnnotationFileId}/offer_post_rows/${offerPostId}`
      },
      transformErrorResponse,
      onQueryStarted: async(args, { queryFulfilled, dispatch }) => {
        const result = await basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe tarjouksen postirivien haussa`,
          returnValue: true
        })
        if(result?.data.records.length) {
          dispatch(offerActions.postRows.fetchSuccess(result.data.records))
        }
      }
    })
  })
})

export { baseApi as offerAnnotationApi }

export const {
  useBringShapeToFrontMutation,
  useCreateAnnotationFileMutation,
  useCreateAnnotationPageMutation,
  useCreateAnnotationShapeMutation,
  useDeleteAnnotationShapeMutation,
  useGetAnnotationFilesQuery,
  useGetAnnotationPageQuery,
  useGetAnnotationPagesQuery,
  useGetAnnotationShapeQuery,
  useGetAnnotationShapesQuery,
  useGetOfferAnnotationTypesQuery,
  useGetOfferPostAnnotationShapesQuery,
  useGetUnassignedOfferPostRowsQuery,
  useSendShapeToBackMutation,
  useUpdateAnnotationFileMutation,
  useUpdateAnnotationPageMutation,
  useUpdateAnnotationShapeMutation,
  useGetAnnotationPageCustomStatesQuery,
  useCreateAnnotationPageCustomStateMutation,
  useUpdateAnnotationPageCustomStateMutation,
  useRemoveAnnotationPageCustomStateMutation
} = baseApi
