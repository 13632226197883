import type { ComponentProps } from 'react'
import { Route } from 'react-router-dom'

import { ForbiddenErrorPage } from '../Errors'
import withPermissionCheck from './withPermissionCheck'

const FallbackRoute = (props: ComponentProps<typeof Route>) => {
  return (
    <Route {...props} render={() => <ForbiddenErrorPage />} />
  )
}

const permissionRouter = <FeatureKeyOfAccessLevelType, >(featureKeyOrAccessLevel: FeatureKeyOfAccessLevelType) => withPermissionCheck(featureKeyOrAccessLevel, Route, FallbackRoute)

export default permissionRouter
