import type { DrawElement, Point } from './types/index.js'

export const calculatePolygonArea = (coordinates: Point[], pixelsPerMeter: number) => {
  if(coordinates.length < 3) {
    return 0
  }
  let area = 0

  // Close polygon
  const closedCoordinates = [...coordinates, coordinates[0]]

  for(let i = 0; i < closedCoordinates.length; i++) {
    const x1 = closedCoordinates[i][0] / pixelsPerMeter
    const y1 = closedCoordinates[i][1] / pixelsPerMeter
    const x2 = closedCoordinates[(i + 1) % closedCoordinates.length][0] / pixelsPerMeter // Wrap around to the first vertex
    const y2 = closedCoordinates[(i + 1) % closedCoordinates.length][1] / pixelsPerMeter

    area += (x1 * y2 - y1 * x2)
  }

  return Math.abs(area) / 2
}

export const calculateDistance = (coordinates: [Point, Point]) => Math.sqrt(Math.pow(coordinates[1][0] - coordinates[0][0], 2) + Math.pow(coordinates[1][1] - coordinates[0][1], 2))

export const getPointCount = (coordinates: Point[]) => coordinates.length

export const getLineLength = (coordinates: Point[], pixelsPerMeter: number) => {
  if(coordinates.length < 2) { return 0 }

  return coordinates.reduce((prev, curr, index) => {
    const nextPoint = coordinates[index + 1]
    if(!nextPoint) { return prev }

    return prev + (calculateDistance([curr, nextPoint]) / pixelsPerMeter)
  }, 0)
}

export interface TypeConfig {
  unit: string
  getValue: (row: DrawElement, pixelsPerMeter: number) => number
}

export const getTypeConfigs = (type: DrawElement['type']): TypeConfig | null => {
  switch(type) {
    case 'line':
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'line' ? getLineLength(row.coordinates, pixelsPerMeter) : 0,
        unit: 'm'
      }
    case 'area':
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'area' ? calculatePolygonArea(row.coordinates, pixelsPerMeter) : 0,
        unit: 'm²'
      }
    case 'point':
      return {
        getValue: row => row.type === 'point' ? getPointCount(row.coordinates) : 0,
        unit: 'kpl'
      }
    case 'wall':
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'wall' ? getLineLength(row.coordinates, pixelsPerMeter) * (row.height ?? 0) : 0,
        unit: 'm²'
      }
    case 'volume': {
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'volume' ? calculatePolygonArea(row.coordinates, pixelsPerMeter) * (row.height ?? 0) : 0,
        unit: 'm³'
      }
    }
    case 'risingPoint':
      return {
        getValue: row => row.type === 'risingPoint' ? row.length ?? 0 : 0,
        unit: 'm'
      }
    case 'circleAreaTemp':
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'circleAreaTemp' && row.radius ? Math.PI * (row.radius / pixelsPerMeter) ** 2 : 0,
        unit: 'm²'
      }

    case 'circleArea':
      return {
        getValue: (row, pixelsPerMeter) => row.type === 'circleArea' ? Math.PI * (row.radius / pixelsPerMeter) ** 2 : 0,
        unit: 'm²'
      }
    default:
      return null
  }
}
