import type { ComponentProps, ElementType } from 'react'

import { useTypedSelector } from '../../reducerTypes'
import { findCurrentEmployeeLevel } from '../../selectors/employeeSelectors'
import { defaultStage, findFeaturePermissionsByKey, getSystemCustomerPermissions } from './featurePermissions'

/**
 * Render props component displaying content based on whoAmI employee level
 *
 * @param {number} featureKeyOrAccessLevel Either numeric employee level ADMIN, USER or feature key (check featurePermissions.js)
 * @param {function} renderHasAccess
 * @param {function} renderFallback
 */

interface PermissionWrapperProps {
  fallbackComponent?: ElementType
  hide?: boolean
}

const withPermissionCheck = <FeatureOrAccessLevelType, HasAccessComponentType extends ElementType, FallBackComponentType extends ElementType>(featureKeyOrAccessLevel: FeatureOrAccessLevelType, HasAccessComponent: HasAccessComponentType, FallbackComponent?: FallBackComponentType) => {
  const { minAccessLevel, stage } = Number.isInteger(Number(featureKeyOrAccessLevel))
    ? { minAccessLevel: Number(featureKeyOrAccessLevel), stage: defaultStage }
    : findFeaturePermissionsByKey(featureKeyOrAccessLevel)

  type HasAccessComponentProps = ComponentProps<typeof HasAccessComponent>
  type FallbackComponentProps = typeof FallbackComponent extends ElementType ? ComponentProps<typeof FallbackComponent> : Record<never, never>

  const PermissionWrapper = ({
    fallbackComponent,
    ...rest
  }: PermissionWrapperProps & HasAccessComponentProps & FallbackComponentProps) => {
    const employeeAccessLevel = useTypedSelector(state => findCurrentEmployeeLevel(state).accessLevel)
    const { systemCustomerStage } = useTypedSelector(state => getSystemCustomerPermissions(state.systemCustomer))
    const Fallback = fallbackComponent || FallbackComponent
    const hasAccess = employeeAccessLevel >= minAccessLevel && systemCustomerStage >= stage
    if(hasAccess) {
      const props = rest as ComponentProps<typeof HasAccessComponent >
      return <HasAccessComponent {...props} />
    }
    if(Fallback) {
      const props = rest as ComponentProps<typeof Fallback >
      return <Fallback {...props} />
    }
    return null
  }

  return PermissionWrapper
}

export default withPermissionCheck
