import { createApi } from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { fetchEmployeeSalaryEvents, normalizeEmployeeResponse } from './employeeApi'
import { optionsQueryBase } from './urlTemplates'
import { normalizeWorkList } from './workApi'

export const normalizeWorkRecords = response => {
  const data = response.record || response.records || response
  const {
    work,
    employees,
    denominations,
    options
  } = response._embedded || {}
  return {
    data,
    work: work?._embedded ? normalizeWorkList(work) : null,
    employee: normalizeEmployeeResponse(employees),
    denominations,
    tableOptions: parseTableOptionsFromQuery(options)
  }
}

export const workRecordApi = createApi({
  base: 'work_records',
  normalizer: normalizeWorkRecords,
  extraUrlTemplate: `{?query*}${optionsQueryBase}`,
  editGetParams: params => {
    const workId = params.workId ?? params.filters?.workId
    return workId
      ? {
          ...parseFilterParams({ ...params }),
          base: 'work',
          id: workId,
          subItem: 'work_records'
        }
      : parseFilterParams(params)
  }
})

export const startWorkRecord = data => workRecordApi.create(data, { subItem: 'start' })
export const stopWorkRecord = data => workRecordApi.create(data, { id: data.id, subItem: 'stop' })
export const approveWorkRecordSalaryEvents = data => workRecordApi.create(data, { subItem: 'approve_salary_events' })

export const fetchWorkRecordSalaryEvents = (params = {}) =>
  params.employeeId
    ? fetchEmployeeSalaryEvents(params)
    : workRecordApi.fetch({ ...params, subItem: 'salary_events' })
