import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialPacketTableOptions = {
  default: getTableOptions(),
  matrix: getTableOptions(),
  offerAnnotationPacketMatrix: getTableOptions()
}

export default getCombinedListReducerFor(constants.actionKeys.PACKETS, {
  installations: getCombinedListReducerFor(constants.actionKeys.INSTALLATIONS),
  packetInstallations: getCombinedListReducerFor(constants.actionKeys.PACKET_INSTALLATIONS),
  packetInstallationPackets: getCombinedListReducerFor(constants.actionKeys.PACKET_INSTALLATION_PACKETS),
  packetInstallationTasks: getCombinedListReducerFor(constants.actionKeys.PACKET_INSTALLATION_TASKS),
  packetInstallationProducts: getCombinedListReducerFor(constants.actionKeys.PACKET_INSTALLATION_PRODUCTS),
  tableOptions: tableReducerFor(constants.actionKeys.PACKETS, initialPacketTableOptions),
  packetGroups: getCombinedListReducerFor(constants.actionKeys.PACKET_GROUPS)
})
