import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import type { FederatedCredentialModel } from '../api/federatedCredentialApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import type { EveliaRootState } from '../reducerTypes'

const federatedCredentialAdapter = createEntityAdapter<FederatedCredentialModel>({})

const federatedCredentialSlice = createGenericSlice({
  name: 'federatedCredentials',
  entityAdapter: federatedCredentialAdapter
})

export const federatedCredentialEntitySelectors = federatedCredentialAdapter.getSelectors<EveliaRootState>(state => state.federatedCredentials)
export const federatedCredentialActions = federatedCredentialSlice.actions

export default federatedCredentialSlice
