import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { findSystemCustomerSettings } from '../../selectors/systemCustomerSelectors'

const MultiFactorAuthenticationInfo = ({ isMfaSetup, message }) => {
  const companyData = useSelector(findSystemCustomerSettings)
  const defaultMessage = `Yritykselle ${companyData.details.name} on määritetty käyttöön kaksivaiheinen tunnistautuminen.${isMfaSetup ? '\n\nOta kaksivaiheinen tunnistautuminen käyttöön seuraamalla näitä ohjeita tai lue tarkemmat ohjeet Evelian oppaasta:\n' : ''}`
  return (
    <Alert style={{ whiteSpace: 'pre-wrap' }} data-testid='mfaInfo'>
      {message || defaultMessage}
      {isMfaSetup
        ? (
          <a href='https://evelia.fi/lisavarmenteen-kayttoonotto/' target='_blank' rel='noopener noreferrer'>
            <FontAwesomeIcon icon={faLink} />
            {' '}
            Lisävarmenteen käyttöönotto
          </a>
          )
        : null}
    </Alert>
  )
}

MultiFactorAuthenticationInfo.propTypes = {
  isMfaSetup: PropTypes.bool,
  message: PropTypes.string
}

export default MultiFactorAuthenticationInfo
