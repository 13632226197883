import { toCamelCase, toSnakeCase } from '@evelia/common/helpers'
import type { IBooleanObject, ILooseObject } from 'redux-crud-mb'
import reduxCrud from 'redux-crud-mb'

export const request = <T>(type: string) => (record: T, data: object) => ({ data, record, type })
export const start = request
export const success = request
export const error = <T>(type: string) => (error: Error, record: T, data: object) => ({ data, error, record, type })

export const requestRecords = <T>(type: string) => (records: T[], data: object) => ({ data, records, type })
export const startRecords = requestRecords
export const successRecords = requestRecords
export const errorRecords = <T>(type: string) => (error: Error, records: T[], data: object) => ({ data, error, records, type })

const clientActionNames = ['edit open', 'edit close', 'new init']

const actionTypeConf = {
  additionalTypes: {
    patch: true,
    search: true
  }
}

const getClientCreatorFunctionFor = <T>(actionName: string, actionTypes: ILooseObject) =>
  (record: T, data: object) => ({
    record,
    data,
    type: actionTypes[actionName]
  })

export const actionTypesFor = (name: string, additionalTypes: IBooleanObject = {}) => {
  const actionTypes = reduxCrud.actionTypesFor(name, { ...actionTypeConf, additionalTypes: { ...actionTypeConf.additionalTypes, ...additionalTypes } })

  const nameUpper = name.toUpperCase()
  clientActionNames.forEach(clientActionName => {
    const type = toSnakeCase(nameUpper, ...clientActionName.split(' ')).toUpperCase()
    const shortcut = toCamelCase(...clientActionName.split(' '))
    actionTypes[type] = type
    actionTypes[shortcut] = type
  })
  actionTypes.setBusy = toSnakeCase(nameUpper, 'set', 'busy').toUpperCase()
  actionTypes.setFetchParams = toSnakeCase(nameUpper, 'set', 'fetch', 'params').toUpperCase()
  return actionTypes
}

export const actionCreatorsFor = (name: string, additionalTypes: IBooleanObject, key = 'id') => {
  const actionTypes = actionTypesFor(name, additionalTypes)
  const actionCreators = {
    ...reduxCrud.actionCreatorsFor(name, { key }),
    searchRequest: (searchTerm: string, data = {}) => ({
      searchTerm,
      data,
      type: actionTypes.searchRequest
    }),
    searchStart: (searchTerm: string, data = {}) => ({
      searchTerm,
      data,
      type: actionTypes.searchStart
    }),
    searchSuccess: (result, searchTerm: string, data = {}) => ({
      result,
      searchTerm,
      data,
      type: actionTypes.searchSuccess
    }),
    searchError: (error: Error, searchTerm: string, data = {}) => ({
      error,
      searchTerm,
      data,
      type: actionTypes.searchError
    }),
    patchRequest: request(actionTypes.patchRequest),
    patchStart: start(actionTypes.patchStart),
    patchSuccess: success(actionTypes.patchSuccess),
    patchError: error(actionTypes.patchError),

    setBusy: (isBusy: boolean) => {
      return {
        type: actionTypes.setBusy,
        isBusy
      }
    },
    setFetchParams: (fetchParams: object) => {
      return {
        type: actionTypes.setFetchParams,
        fetchParams
      }
    },
    actionTypes
  }

  clientActionNames.forEach(clientActionName => {
    const shortcut = toCamelCase(...clientActionName.split(' '))
    actionCreators[shortcut] = getClientCreatorFunctionFor(shortcut, actionTypes)
  })

  if(additionalTypes) {
    Object.entries(additionalTypes).forEach(([key, async]) => {
      if(async) {
        actionCreators[`${key}Request`] = request(actionTypes[`${key}Request`])
      }
      actionCreators[`${key}Start`] = start(actionTypes[`${key}Start`])
      actionCreators[`${key}Success`] = success(actionTypes[`${key}Success`])
      actionCreators[`${key}Error`] = error(actionTypes[`${key}Error`])
    })
  }
  return actionCreators
}
