import ActionPromptModal from './ActionPromptModal'

interface ShowWarehouseInventoryErrorsProps {
  visible: boolean
  data: { productErrors: string[] } | { message: string }
  hideActionPrompt: () => void
}

const ShowWarehouseInventoryErrors = ({ visible, data, hideActionPrompt }: ShowWarehouseInventoryErrorsProps) => {
  const body = (
    <ul>
      {'productErrors' in data
        ? data.productErrors.map(error => <li key={error}>{error}</li>)
        : 'message' in data ? <li>{data.message}</li> : null}
    </ul>
  )

  return (
    <ActionPromptModal
      visible={visible}
      renderBody={body}
      title='Virheitä varastoinventaariossa!'
      onCancel={hideActionPrompt}
    />
  )
}

export default ShowWarehouseInventoryErrors
