/* eslint-disable no-template-curly-in-string */

import type { LocaleObject } from 'yup'
import { ValidationError } from 'yup'

import { formatDateTime } from '../dateHelpers.js'
import { callFunctionOrContent } from '../helpers.js'

/* c8 ignore next */
const getTypeString = (type: string) => {
  switch(type) {
    case 'number':
      return 'numero'
    case 'boolean':
      return 'tosi/epätosi'
    case 'string':
      return 'tekstiä'
    case 'date':
      return 'päivämäärä'
    case 'object':
      return 'objekti'
    case 'array':
      return 'taulukko'
  }
}

const mixed: Required<LocaleObject['mixed']> = {
  default: '${path} on virheellinen',
  required: '${path} on pakollinen kenttä',
  oneOf: '${path} pitää olla jokin seuraavista: "${values}"',
  notOneOf: '${path} ei saa olla mikään seuraavista: "${values}"',
  notType: ({ path, type }) => `${path} pitää olla ${getTypeString(type)}`,
  defined: '${path} pitää olla määritetty',
  notNull: '${path} ei saa olla \'null\''
}

const number: Required<LocaleObject['number']> = {
  integer: '${path} pitää olla kokonaisluku',
  min: '${path} pitää olla vähintään ${min}',
  max: '${path} saa olla enintään ${max}',
  lessThan: '${path} pitää olla vähemmän kuin ${less}',
  moreThan: '${path} pitää olla enemmän kuin ${more}',
  positive: '${path} pitää olla positiivinen numero',
  negative: '${path} pitää olla negatiivinen numero'
}

const string: Required<LocaleObject['string']> = {
  length: '${path} pitää olla ${length} merkkiä pitkä',
  min: '${path} pitää olla vähintään ${min} merkkiä pitkä',
  max: '${path} saa olla enintään ${max} merkkiä pitkä',
  matches: '${path} pitää olla seuraavaa muotoa: "${regex}"',
  email: '${path} pitää olla sähköpostimuotoa',
  url: '${path} pitää olla kelvollinen URL',
  uuid: '${path} pitää olla kelvollinen UUID',
  trim: '${path} ei saa alkaa tai päättyä välilyöntiin',
  lowercase: '${path} pitää olla pienillä kirjaimilla',
  uppercase: '${path} pitää olla isoilla kirjaimilla',
  datetime: '${path} tulee olla päivä ja kellonaika ISO-muodossa',
  datetime_precision: '${path} tulee olla päivä ja kellonaika ISO-muodossa ja sekuntien olla ${precision} desimaalin tarkkuudella',
  datetime_offset: '${path} tulee olla päivä ja kellonaika ISO-muodossa UTC-aikavyöhykkeellä (Z)'
}

const date: Required<LocaleObject['date']> = {
  min: ({ path, min }) => `${path} pitää olla vähintään ${formatDateTime(callFunctionOrContent(min))}`,
  max: ({ path, max }) => `${path} saa olla enintään ${formatDateTime(callFunctionOrContent(max))}`
}

const boolean: Required<LocaleObject['boolean']> = {
  isValue: '${path} tulee olla ${value}'
}

const object: Required<LocaleObject['object']> = {
  noUnknown: '${path} sisältää virheellisiä avaimia: ${unknown}',
  exact: '${path} sisältää virheellisiä avaimia, sallittuja avaimia ovat: ${properties}'
}

const array: Required<LocaleObject['array']> = {
  length: '${path} tulee pitää sisällään tarkalleen ${length} kohdetta',
  min: ({ path, min }) => min === 1 ? `${path} ei voi olla tyhjä` : `${path} pitää sisältää vähintään ${min} kohdetta`,
  max: '${path} pitää sisältää enintään ${max} kohdetta'
}

const tuple: Required<LocaleObject['tuple']> = {
  notType: params => {
    const { path, value, spec } = params
    const typeLen = spec.types.length
    if(Array.isArray(value)) {
      if(value.length < typeLen) {
        return `${path}-monikko sisältää liian vähän kohteita, kohteita tulee olla ${typeLen} mutta kohteita oli ${
          value.length
        } arvolla '${JSON.stringify(value)}'`
      }
      if(value.length > typeLen) {
        return `${path}-monikko sisältää liian monta kohdetta, kohteita tulee olla ${typeLen} mutta kohteita oli ${
          value.length
        } arvolla '${JSON.stringify(value)}'`
      }
    }

    return ValidationError.formatError(mixed.notType, params)
  }
}

export const locale: Required<LocaleObject> = {
  mixed,
  number,
  string,
  date,
  boolean,
  object,
  array,
  tuple
}
