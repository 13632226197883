import type { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import type { SerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import uniqBy from 'lodash/uniqBy'
import queryString from 'query-string'

import contactActions from '../../actions/contactActions'
import customerActions from '../../actions/customerActions'
import projectActions from '../../actions/projectActions'
import supplyOfferActions from '../../actions/supplyOfferActions'
import targetActions from '../../actions/targetActions'
import type { DefaultArgs } from '../../components/Kanban/Kanban'
import { getBaseQuery } from './apiHelpers'
import type { SupplyOfferKanbanModel } from './supplyOfferKanbanApi'
import type { ApiResponse } from './types/api'

const SUPPLY_OFFER_DEFAULT_LIMIT = 25

const serializeSupplyOfferQueryArgs: SerializeQueryArgs<DefaultArgs, Partial<DefaultArgs>> = data => {
  const newQueryArgs = { ...data.queryArgs as DefaultArgs }
  delete newQueryArgs.page
  delete newQueryArgs.limit
  delete newQueryArgs.extraFilters
  delete newQueryArgs.cacheBust
  delete newQueryArgs.sort
  return newQueryArgs
}

export type SupplyOfferModel = {
  id: number
  name: string
  supplyOfferNumber: number
  description: null
  targetId: number | null
  customerId: number
  supervisorId: number | null
  projectId: number | null
  isVatPrimary: boolean
  pricingRuleSetId: number | null
  vatType: null | string
  supplyOfferTypeId: number
  supplyOfferCustomStateId: number
  supplyOfferValidUntil: string
  paymentTerm: string
  yourReference: null
  supplyOfferDate: string
  workId: number
  deliveryTime: string
  contactId: number | null
}

export const supplyOfferApi = createApi({
  reducerPath: 'supplyOfferApi',
  baseQuery: getBaseQuery('supply_offers'),
  endpoints: builder => ({
    getSupplyOffers: builder.query<ApiResponse<SupplyOfferModel>, DefaultArgs>({
      query: query => {
        const {
          mainFilter,
          extraFilters = [],
          page,
          limit,
          sort
        } = query
        const extraFiltersFormatted = extraFilters.flatMap(extraProp => extraProp.values.map(value => [extraProp.field, value]))
        const filters = (extraFiltersFormatted as (string | number | boolean)[][]).concat(mainFilter)
        const orderBy = sort?.sortKey
        const sortOrder = sort?.order

        const q = queryString.stringify({ filters, page, limit: limit ?? SUPPLY_OFFER_DEFAULT_LIMIT, orderBy, sortOrder })
        return `?${q}`
      },
      serializeQueryArgs: serializeSupplyOfferQueryArgs,
      merge: (currentCache, newItems, { arg }) => {
        if(currentCache.records && !arg.cacheBust) {
          return {
            // Prevent storing duplicate items
            records: uniqBy([...currentCache.records, ...newItems.records].reverse(), 'id').reverse(),
            _embedded: { ...newItems._embedded }
          }
        }
        return newItems
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.page !== previousArg?.page
      },
      keepUnusedDataFor: Infinity
    })
  })
})

export const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, _embedded: ApiResponse<SupplyOfferModel | SupplyOfferKanbanModel>['_embedded']) => {
  // Dispatch embedded data into base redux state
  dispatch(customerActions.fetchSuccess(_embedded.customers ?? []))
  dispatch(targetActions.fetchSuccess(_embedded.targets ?? []))
  dispatch(projectActions.fetchSuccess(_embedded.projects ?? []))
  dispatch(contactActions.fetchSuccess(_embedded.contacts ?? []))
}

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: supplyOfferApi.endpoints.getSupplyOffers.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      // Dispatch work into base redux state
      listenerApi.dispatch(supplyOfferActions.fetchSuccess(action.payload.records))
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: supplyOfferApiMiddleware } = listenerMiddleware
