import { useSelector } from 'react-redux'

import { serviceLevels } from '../../constants'
import AppNavLogo from './AppNavLogo'

const serviceLevelTitles = {
  [serviceLevels.SERVICE_LEVEL_PRO]: 'Pro',
  [serviceLevels.SERVICE_LEVEL_PLUS]: 'Plus',
  [serviceLevels.SERVICE_LEVEL_START]: 'Start',
  [serviceLevels.SERVICE_LEVEL_INVOICING]: 'Laskutus'
}

const ServiceLevelNavLogo = () => {
  const serviceLevel = useSelector(state => state.whoAmI?.data?.serviceLevel)
  const serviceLevelTitle = serviceLevelTitles[serviceLevel]
  return (
    <div className='position-relative'>
      <AppNavLogo />
      <small className='position-fixed text-secondary'>
        {serviceLevelTitle}
      </small>
    </div>
  )
}

export default ServiceLevelNavLogo
