import { externalApiText, factoringProviderIds, invoicingTypeParams } from '@evelia/common/constants'
import { isDefined, removeDuplicates } from '@evelia/common/helpers'
import { getInvoiceFormSettingsByType } from '@evelia/pdf/components/invoice/invoiceFormSettingParser'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  actionKeys,
  factoringOptions,
  invoiceInternalStatuses,
  invoiceTypes,
  reminderInvoiceStates
} from '../constants'
import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import { selectFileEntities } from '../slices/filesSlice'
import { findInitialAccounting } from './accountingSelectors'
import { findCustomerGroupWithId, getCustomerGroupsFromArgument } from './customerGroupSelectors'
import { findCustomerWithId, getCustomersFromArgument } from './customerSelectors'
import { getDefaultInvoiceFormTypeOfCompany } from './formTypeSelectors'
import { findProjectWithId, getProjectsFromArgument } from './projectSelectors'
import { findSystemCustomerSettings } from './systemCustomerSelectors'
import { getHasAccessToFeature } from './whoAmISelectors'
import { findProjectByWorkId, findWorkWithId, getWorkFromArguments } from './workSelectors'

const getInvoicesFromArgument = arg => arg.invoices ? arg.invoices.records : arg
export const getInterestInvoicingsFromArgument = arg => arg.invoices?.interestInvoicing.records || arg
const getInvoiceFormSettingsFromArgument = arg => arg.invoices?.formSettings.records

export const findInvoiceWithId = getFindItemByIdSelector(getInvoicesFromArgument)

export const getInvoicesByTableIds = getTableIdsSelector('invoices')

export const getInvoiceFileLinks = createArgumentSelector(
  (state, __invoiceId) => state.invoices.files.records,
  (__state, invoiceId) => invoiceId,
  (fileLinks, invoiceId) => fileLinks.filter(fileLink => fileLink.invoiceId === invoiceId)
)

export const getInvoiceFileLink = createArgumentSelector(
  (state, __fileId) => state.invoices.files.records,
  (__state, fileId) => fileId,
  (fileLinks, fileId) => fileLinks.find(fileLink => fileLink.fileId === fileId)
)

export const getFilesOfInvoice = createArgumentSelector(
  selectFileEntities,
  getInvoiceFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

export const findInvoiceFilesWithoutSystemTagsIncludingLinks = createArgumentSelector(
  selectFileEntities,
  getInvoiceFileLinks,
  (__state, __invoiceId, systemTags) => systemTags,
  (fileEntities, fileLinks, systemTags) => fileLinks.map(fileLink => {
    const file = fileEntities[fileLink.fileId]
    return file && ((file.systemTag ?? 0) & systemTags) === 0 && (fileLink.systemTag & systemTags) === 0 ? file : null
  }).filter(isDefined)
)

export const findInvoicesByCustomerId = getFilterItemsByFieldSelector(getInvoicesFromArgument, 'customerId', Number)

export const findInvoicesByTargetId = getFilterItemsByFieldSelector(getInvoicesFromArgument, 'targetId', Number)

export const getInvoiceAccountingsByInvoice = getFilterItemsByFieldSelector(state => state.invoices.accountings.records, 'invoiceId', Number)

export const getInitialInvoiceAccountingByInvoice = createCachedSelector(
  getInvoiceAccountingsByInvoice,
  findInitialAccounting
)((state, invoiceId) => `${invoiceId}`)

export const findMemosByInvoiceId = getMemoSelector('invoices', 'invoiceId')

export const getInvoicesFromSearchResult = getItemsFromSearchResultsSelector('invoices', getInvoicesFromArgument, findInvoiceWithId)

// TODO: move status computation to backend
export const getInternalStatus = invoice =>
  invoiceInternalStatuses[invoice?.internalStatus] || invoiceInternalStatuses.DEFAULT

export const getReminderState = invoice => invoice.reminderState ? reminderInvoiceStates.REMINDER_SENT : null

const getInvoicesByParentId = createCachedSelector(
  getInvoicesFromArgument,
  (state, parentInvoiceId) => Number(parentInvoiceId),
  (state, parentInvoiceId, type) => type,
  (invoices, parentInvoiceId, type) =>
    invoices.filter(invoice =>
      invoice.parentInvoiceId === parentInvoiceId &&
      (type ? invoice.type === type : true)
    )
)((state, parentInvoiceId, type = null) => `${parentInvoiceId}_${type}`)

export const getReminderInvoicesByParentId = createCachedSelector(
  getInvoicesFromArgument,
  (state, parentInvoiceId) => Number(parentInvoiceId),
  (invoices, parentInvoiceId) => getInvoicesByParentId(invoices, parentInvoiceId, invoiceTypes.INVOICE_TYPE_REMINDER)
)((state, parentInvoiceId) => `${parentInvoiceId}`)

export const getInterestInvoiceInvoices = getFilterItemsByFieldSelector(getInvoicesFromArgument, 'interestInvoiceId')

export const getCreditInvoicesByParentId = createCachedSelector(
  getInvoicesFromArgument,
  (state, parentInvoiceId) => Number(parentInvoiceId),
  (invoices, parentInvoiceId) => getInvoicesByParentId(invoices, parentInvoiceId, invoiceTypes.INVOICE_TYPE_CREDIT)
)((state, parentInvoiceId) => `${parentInvoiceId}`)

export const getCreditedState = createCachedSelector(
  getCreditInvoicesByParentId,
  creditInvoices => !!creditInvoices.length
)((state, invoiceId) => `${invoiceId}`)

export const getInvoicesByRows = createCachedSelector(
  getInvoicesFromArgument,
  (state, invoiceRows) => invoiceRows,
  (invoices, invoiceRows) =>
    removeDuplicates(invoiceRows.map(invoiceRow => invoiceRow.invoiceId))
      .map(invoiceId => findInvoiceWithId(invoices, invoiceId)).filter(Boolean)
)((state, invoiceRows) => invoiceRows.map(invoiceRow => invoiceRow.id).join(','))

export const findInvoiceOccurrenciesByWorkId = createCachedSelector(
  getInvoicesFromArgument,
  getWorkFromArguments,
  (state, templateWorkId) => Number(templateWorkId),
  (invoices, work, templateWorkId) => {
    const workOccurenceIds = work.filter(w => w.templateWorkId === templateWorkId).map(({ id }) => id)
    return invoices.filter(i => workOccurenceIds.includes(i.workId))
  }
)((state, templateWorkId) => `${templateWorkId}`)

const findFormSettingsByInvoiceId = (invoiceFormSettings, invoiceId) =>
  invoiceFormSettings?.find(formSetting => formSetting.invoiceId === invoiceId) || null

export const findExistingInvoiceFormSettingsByInvoiceId = createCachedSelector(
  getInvoicesFromArgument,
  getInvoiceFormSettingsFromArgument,
  (state, invoiceId) => Number(invoiceId),
  getDefaultInvoiceFormTypeOfCompany,
  (invoices, formSettings, invoiceId, defaultFormTypeOfCompany) => {
    const invoice = findInvoiceWithId(invoices, invoiceId)
    const invoiceFormSettings = findFormSettingsByInvoiceId(formSettings, invoiceId)
    const parentInvoiceFormSettings = invoice.parentInvoiceId ? findFormSettingsByInvoiceId(formSettings, invoice.parentInvoiceId) : null
    const defaultInvoiceFormSettings = defaultFormTypeOfCompany

    return getInvoiceFormSettingsByType(invoice.type, invoiceFormSettings, parentInvoiceFormSettings, defaultInvoiceFormSettings)
  }
)((state, invoiceId) => `${invoiceId}`)

const getFactoringProviderName = maventa => {
  return factoringOptions.find(option => option.value === maventa.factoringProvider)?.text || '-'
}

export const getInvoicingOptionsBySystemCustomer = createSelector(
  findSystemCustomerSettings,
  state => state.whoAmI.data.externalInvoicingApi,
  ({ maventa, settings }, externalInvoicingApi) => {
    if(externalInvoicingApi) {
      return [
        {
          value: invoicingTypeParams.EXTERNAL,
          text: `Laskutus ulkoisessa järjestelmässä (${externalApiText[externalInvoicingApi].externalApiName})`
        }
      ]
    } else {
      return [
        {
          value: invoicingTypeParams.MAVENTA,
          text: maventa?.apiKey != null
            ? 'Maventa'
            : 'Maventa (ei käytössä)'
        },
        {
          value: invoicingTypeParams.FACTORING,
          text: maventa.factoringContractId != null && maventa.factoringProvider !== factoringProviderIds.NOT_ENABLED
            ? getFactoringProviderName(maventa)
            : 'Laskurahoitus (ei käytössä)'
        },
        {
          value: invoicingTypeParams.EMAIL,
          text: settings.noEmailInvoicing
            ? 'Sähköposti (ei käytössä)'
            : 'Sähköposti'
        },
        {
          value: invoicingTypeParams.PDF,
          text: 'Tulostus'
        }
      ]
    }
  }
)

export const getDefaultSettingsInvoicingType = createSelector(
  findSystemCustomerSettings,
  state => state.whoAmI.data.externalInvoicingApi,
  ({ maventa }, externalInvoicingApi) => {
    let defaultInvoicingType
    if(externalInvoicingApi) {
      defaultInvoicingType = invoicingTypeParams.EXTERNAL
    } else if(maventa.invoicingType) {
      defaultInvoicingType = maventa.invoicingType
    } else if(maventa?.apiKey && maventa?.factoringProvider === factoringProviderIds.NOT_ENABLED) {
      defaultInvoicingType = invoicingTypeParams.MAVENTA
    } else if(maventa.factoringContractId && maventa.factoringProvider !== factoringProviderIds.NOT_ENABLED) {
      defaultInvoicingType = invoicingTypeParams.FACTORING
    } else {
      defaultInvoicingType = null
    }
    return defaultInvoicingType
  }
)

export const getDefaultInvoicingType = createSelector(
  getDefaultSettingsInvoicingType,
  defaultSettingsInvoicingType =>
    defaultSettingsInvoicingType || invoicingTypeParams.EMAIL
)

const getEffectiveInvoicingType = (
  hasAccessToInvoicingType,
  defaultInvoicingType,
  workInvoicingType,
  projectInvoicingType,
  customerInvoicingType,
  customerGroupInvoicingType,
  customerInvoicingTypeSecondary,
  customerGroupInvoicingTypeSecondary
) => {
  let invoicingType
  let useInvoicingTypeOf
  if(!hasAccessToInvoicingType) {
    invoicingType = defaultInvoicingType
    useInvoicingTypeOf = 'defaultInvoicingType'
  } else if(workInvoicingType) {
    invoicingType = workInvoicingType
    useInvoicingTypeOf = 'workInvoicingType'
  } else if(projectInvoicingType) {
    invoicingType = projectInvoicingType
    useInvoicingTypeOf = 'projectInvoicingType'
  } else if(customerInvoicingType) {
    invoicingType = customerInvoicingType
    useInvoicingTypeOf = 'customerInvoicingType'
  } else if(customerGroupInvoicingType) {
    invoicingType = customerGroupInvoicingType
    useInvoicingTypeOf = 'customerGroupInvoicingType'
  } else if(customerInvoicingTypeSecondary) {
    invoicingType = customerInvoicingTypeSecondary
    useInvoicingTypeOf = 'customerInvoicingTypeSecondary'
  } else if(customerGroupInvoicingTypeSecondary) {
    invoicingType = customerGroupInvoicingTypeSecondary
    useInvoicingTypeOf = 'customerGroupInvoicingTypeSecondary'
  } else {
    invoicingType = defaultInvoicingType
    useInvoicingTypeOf = 'defaultInvoicingType'
  }

  return {
    useInvoicingTypeOf,
    invoicingType
  }
}

export const getSettingsInvoicingType = createSelector(
  getDefaultSettingsInvoicingType,
  state => getHasAccessToFeature(state, actionKeys.INVOICING_TYPE),
  (defaultInvoicingType, hasAccessToInvoicingType) => {
    return getEffectiveInvoicingType(
      hasAccessToInvoicingType,
      defaultInvoicingType
    )
  }
)

export const getInvoiceEffectiveInvoicingTypeByWorkId = createCachedSelector(
  findWorkWithId,
  findProjectByWorkId,
  getCustomersFromArgument,
  getCustomerGroupsFromArgument,
  getDefaultInvoicingType,
  state => getHasAccessToFeature(state, actionKeys.INVOICING_TYPE),
  (work, project, customers, customerGroups, defaultInvoicingType, hasAccessToInvoicingType) => {
    const workCustomer = findCustomerWithId(customers, work?.customerId)
    const workCustomerGroup = findCustomerGroupWithId(customerGroups, workCustomer?.customerMainGroupId)
    const projectCustomer = project ? findCustomerWithId(customers, project?.customerId) : null
    const projectCustomerGroup = project ? findCustomerGroupWithId(customerGroups, projectCustomer?.customerMainGroupId) : null

    return getEffectiveInvoicingType(
      hasAccessToInvoicingType,
      defaultInvoicingType,
      work?.invoicingType,
      project?.invoicingType,
      workCustomer?.invoicingType,
      workCustomerGroup?.invoicingType,
      projectCustomer?.invoicingType,
      projectCustomerGroup?.invoicingType
    )
  }
)((__state, workId) => `${workId}`)

export const getWorkEffectiveInvoicingType = createCachedSelector(
  (state, workId) => Number(workId),
  (state, workId, customerId) => Number(customerId),
  (state, workId, customerId, projectId) => Number(projectId),
  getWorkFromArguments,
  getProjectsFromArgument,
  getCustomersFromArgument,
  getCustomerGroupsFromArgument,
  getDefaultInvoicingType,
  state => getHasAccessToFeature(state, actionKeys.INVOICING_TYPE),
  (workId, customerId, projectId, allWork, projects, customers, customerGroups, defaultInvoicingType, hasAccessToInvoicingType) => {
    const work = workId ? findWorkWithId(allWork, workId) : null
    const workCustomer = customerId ? findCustomerWithId(customers, customerId) : null
    const workCustomerGroup = workCustomer?.customerMainGroupId ? findCustomerGroupWithId(customerGroups, workCustomer?.customerMainGroupId) : null
    const project = projectId ? findProjectWithId(projects, projectId) : null
    const projectCustomer = project?.customerId ? findCustomerWithId(customers, project?.customerId) : null
    const projectCustomerGroup = projectCustomer?.customerMainGroupId ? findCustomerGroupWithId(customerGroups, projectCustomer?.customerMainGroupId) : null

    return getEffectiveInvoicingType(
      hasAccessToInvoicingType,
      defaultInvoicingType,
      work?.invoicingType,
      project?.invoicingType,
      workCustomer?.invoicingType,
      workCustomerGroup?.invoicingType,
      projectCustomer?.invoicingType,
      projectCustomerGroup?.invoicingType
    )
  }
)((__state, workId, customerId, projectId) => `${workId}_${customerId}_${projectId}`)

export const getProjectEffectiveInvoicingType = createCachedSelector(
  (state, projectId) => Number(projectId),
  (state, workId, customerId) => Number(customerId),
  getProjectsFromArgument,
  getCustomersFromArgument,
  getCustomerGroupsFromArgument,
  getDefaultInvoicingType,
  state => getHasAccessToFeature(state, actionKeys.INVOICING_TYPE),
  (projectId, customerId, projects, customers, customerGroups, defaultInvoicingType, hasAccessToInvoicingType) => {
    const project = projectId ? findProjectWithId(projects, projectId) : null
    const customer = customerId ? findCustomerWithId(customers, customerId) : null
    const customerGroup = customer?.customerMainGroupId ? findCustomerGroupWithId(customerGroups, customer?.customerMainGroupId) : null

    return getEffectiveInvoicingType(
      hasAccessToInvoicingType,
      defaultInvoicingType,
      null,
      project?.invoicingType,
      customer?.invoicingType,
      customerGroup?.invoicingType
    )
  }
)((__state, projectId, customerId) => `${projectId}_${customerId}`)
