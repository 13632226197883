import { isDefined } from '@evelia/common/helpers'
import type { InboundInvoiceModel, ReceiverApproverModel, ReceiverModel, RequiredFields } from '@evelia/common/types'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import {
  createArgumentSelector,
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector
} from '../helpers/typedSelectorHelpers'
import type { EveliaRootState, RootState } from '../reducerTypes'
import { selectFileEntities } from '../slices/filesSlice'
import { findInboundInvoiceWithId } from './inboundInvoiceSelectors'

export const getReceiversFromArgument = (arg: RootState | ReceiverModel[]) => Array.isArray(arg) ? arg : arg.receivers.records
const getReceiverApproversFromArgument = (arg: RootState | ReceiverApproverModel[]) => Array.isArray(arg) ? arg : arg.receivers.approvers.records

export const findReceiverWithId = getFindItemByIdSelector(getReceiversFromArgument)

export const getReceiverFileLinks = createArgumentSelector(
  (state: EveliaRootState, __receiverId) => state.receivers.files.records,
  (__state, receiverId: ReceiverModel['id']) => receiverId,
  (fileLinks, receiverId) => fileLinks.filter(fileLink => fileLink.receiverId === receiverId)
)

export const getFilesOfReceiver = createArgumentSelector(
  selectFileEntities,
  getReceiverFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

export const getPaymentProhibitedReceivers = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(receiver => receiver.isPaymentProhibited)
)

export const getReceiverApproversByReceiver = getFilterItemsByFieldSelector(getReceiverApproversFromArgument, ['receiverId'])

export const findReceiverByInboundInvoiceId = createCachedSelector(
  [
    (state: RootState, { inboundInvoiceId }: { inboundInvoiceId?: number | null }) => getReceiversFromArgument(state),
    // @ts-expect-error not typed selector
    (state, { inboundInvoiceId }) => findInboundInvoiceWithId(state, inboundInvoiceId)
  ],
  (receivers, inboundInvoice?: InboundInvoiceModel) => inboundInvoice?.receiverId == null ? null : receivers.find(receiver => receiver.id === inboundInvoice.receiverId)
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

export const getReceiversWithSupplierId = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(({ supplierId }) => supplierId != null) as RequiredFields<ReceiverModel, 'supplierId'>[]
)
