import { inboundInvoiceRowTypes, inboundInvoiceTypes, systemFileTags } from '@evelia/common/constants'
import { castToArray, isDefined, removeDuplicates } from '@evelia/common/helpers'
import type { InboundInvoiceModel } from '@evelia/common/types'
import groupBy from 'lodash/groupBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { inboundInvoiceStates } from '../constants'
import {
  getFilterItemsByFieldSelector,
  getFilterItemsByFieldWithValueArraySelector,
  getFindItemByIdSelector,
  getFindItemsByIdsSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import type { EveliaRootState } from '../reducerTypes'
import { selectFileEntities } from '../slices/filesSlice'
import { findInitialAccounting } from './accountingSelectors'

export const getInboundInvoicesFromArgument = arg => arg.inboundInvoices ? arg.inboundInvoices.records : arg
const getRecurrenceRulesFromArgument = arg => arg?.inboundInvoices?.recurrenceRules?.records ?? arg
const getInboundInvoiceStatusesFromArgument = arg => arg?.inboundInvoices?.statuses?.records ?? arg

export const findInboundInvoiceWithId = getFindItemByIdSelector(getInboundInvoicesFromArgument)
export const findInboundInvoicesWithIdList = getFindItemsByIdsSelector(getInboundInvoicesFromArgument)

export const getInboundInvoicesByTableIds = getTableIdsSelector('inboundInvoices')

// @ts-expect-error selector helper not typed
export const getRowsOfInboundInvoice = getFilterItemsByFieldSelector((state: EveliaRootState) => state.inboundInvoices.rows.records, 'inboundInvoiceId', Number)

export const getWarehousableInboundInvoiceRowIds = createSelector(
  // @ts-expect-error selector not typed
  (state, inboundInvoiceId) => getRowsOfInboundInvoice(state, inboundInvoiceId),
  rows => rows.filter(row => !row.warehouseId && row.productId).map(row => row.id)
)

export const getInboundInvoiceFileLinks = createArgumentSelector(
  (state: EveliaRootState, __inboundInvoiceId) => state.inboundInvoices.files.records,
  (__state, inboundInvoiceId: InboundInvoiceModel['id']) => inboundInvoiceId,
  (fileLinks, inboundInvoiceId) => fileLinks.filter(fileLink => fileLink.inboundInvoiceId === inboundInvoiceId)
)

export const getFilesOfInboundInvoice = createArgumentSelector(
  selectFileEntities,
  getInboundInvoiceFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

/**
 * Finds file that has a link to the inbound invoice and an inbound invoice image system tag
 */
const findInboundInvoiceFileWithImageFileLink = createArgumentSelector(
  selectFileEntities,
  getInboundInvoiceFileLinks,
  (fileEntities, fileLinks) => {
    const linkWithFileTag = fileLinks.find(fileLink => fileLink.systemTag & systemFileTags.TAG_INBOUND_INVOICE_IMAGE)
    return linkWithFileTag ? fileEntities[linkWithFileTag.fileId] : null
  }
)

/**
 * Checks both inbound image file links and files of the inbound invoice for an inbound image system tag and the correct file type.
 */
export const findInboundInvoiceImageLink = createArgumentSelector(
  findInboundInvoiceFileWithImageFileLink,
  getFilesOfInboundInvoice,
  (fileWithLinkImageTag, files) => {
    // Return file with system tag match from links if it has correct type
    if(fileWithLinkImageTag && fileWithLinkImageTag.fileType === 'application/pdf') {
      return fileWithLinkImageTag
    }
    // Return potential match from files of the inbound invoice
    return files.find(file =>
      file.systemTag & systemFileTags.TAG_INBOUND_INVOICE_IMAGE &&
      file.fileType === 'application/pdf'
    ) ?? null
  }
)

export const findMemosByInboundInvoiceId = getMemoSelector('inboundInvoices', 'inboundInvoiceId')

export const getInboundInvoicesFromSearchResult = getItemsFromSearchResultsSelector('inboundInvoices', getInboundInvoicesFromArgument, findInboundInvoiceWithId)

const getInboundInvoicesByType = createCachedSelector(
  getInboundInvoicesFromArgument,
  (state, type) => type,
  (inboundInvoices, type) => inboundInvoices.filter(inboundInvoice => inboundInvoice.type === type)
)((state, type) => type)

const getInboundInvoiceStatusesByType = createCachedSelector(
  getInboundInvoiceStatusesFromArgument,
  (state, type) => type,
  (inboundInvoiceStatuses, type) => inboundInvoiceStatuses.filter(inboundInvoiceStatus => inboundInvoiceStatus.type === type)
)((state, type) => type)

const getInboundInvoicesByStates = createCachedSelector(
  (state, inboundInvoiceStates, reject, type, key) => getInboundInvoicesByType(state, type || inboundInvoiceTypes.INBOUND_INVOICE_TYPE_INVOICE),
  (state, inboundInvoiceStates, reject, type, key) => inboundInvoiceStates,
  (state, inboundInvoiceStates, reject, type, key) => !!reject,
  (state, inboundInvoiceStates, reject, type, key) => key || 'state',
  (inboundInvoices, inboundInvoiceStates, reject, key) => {
    const stateArray = castToArray(inboundInvoiceStates)
    return inboundInvoices.filter(inboundInvoice => reject !== stateArray.includes(inboundInvoice[key]))
  }
)((state, inboundInvoiceStates, reject, type, key) => [castToArray(inboundInvoiceStates).join(' '), reject, type, key].join(' | '))

const getInboundInvoiceStatusesByStates = createCachedSelector(
  (state, inboundInvoiceStates, reject, type, key) => getInboundInvoiceStatusesByType(state, type || inboundInvoiceTypes.INBOUND_INVOICE_TYPE_INVOICE),
  (state, inboundInvoiceStates, reject, type, key) => inboundInvoiceStates,
  (state, inboundInvoiceStates, reject, type, key) => !!reject,
  (state, inboundInvoiceStates, reject, type, key) => key || 'state',
  (inboundInvoiceStatuses, inboundInvoiceStates, reject, key) => {
    const stateArray = castToArray(inboundInvoiceStates)
    return inboundInvoiceStatuses.filter(inboundInvoice => reject !== stateArray.includes(inboundInvoice[key]))
  }
)((state, inboundInvoiceStates, reject, type, key) => [castToArray(inboundInvoiceStates).join(' '), reject, type, key].join(' | '))

export const usableInboundInvoiceCreditPaymentStates = [
  inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID,
  inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING,
  inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_FAILED
]

const unapprovedStatuses = [
  inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED,
  inboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED_FULL,
  inboundInvoiceStates.INBOUND_INVOICE_STATE_WAITING_APPROVAL
]

const approvedStatuses = [inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED]
const unrejectedStatuses = [...unapprovedStatuses, inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED]

const getUnrejectedInboundInvoices = state => getInboundInvoiceStatusesByStates(state, unrejectedStatuses, false)
const getAcceptedCreditInboundInvoices = state => getInboundInvoicesByStates(state, approvedStatuses, false, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_CREDIT)

export const getOpenCreditInboundInvoicesByReceiver = createCachedSelector(
  getAcceptedCreditInboundInvoices,
  (state, receiverId) => Number(receiverId),
  (creditInboundInvoices, receiverId) =>
    creditInboundInvoices.filter(creditInboundInvoice =>
      creditInboundInvoice.receiverId === receiverId && usableInboundInvoiceCreditPaymentStates.includes(creditInboundInvoice.paymentState)
    )
)((state, receiverId) => receiverId)

// @ts-expect-error selector not typed
const getApprovalsOfInboundInvoice = getFilterItemsByFieldSelector(state => state.inboundInvoices.approvals.records, 'inboundInvoiceId', Number)
// @ts-expect-error selector not typed
const getApproversOfInboundInvoice = getFilterItemsByFieldSelector(state => state.inboundInvoices.approvers.records, 'inboundInvoiceId', Number)

export const getApprovalDataOfInboundInvoice = createCachedSelector(
  getApproversOfInboundInvoice,
  getApprovalsOfInboundInvoice,
  (approvers, approvals) => {
    const grouped = groupBy(approvals, 'inboundInvoiceApproverId')
    return approvers.flatMap(approver => {
      const approvals = grouped[approver.id] ?? []
      const isPaymentApproved = approvals.length === 0 ? null : approvals.some(approval => approval.isPaymentApproved)
      const isApproved = (approvals.slice(-1).pop())?.isApproved
      return [
        {
          ...approver,
          isApprover: true,
          isPaymentApproved,
          isApproved
        },
        ...approvals.map(approval => ({
          ...approval,
          employeeId: approver.employeeId
        }))
      ]
    })
  }
)((state, inboundInvoiceId) => `${inboundInvoiceId}`)

// @ts-expect-error selector not typed
export const getInboundInvoiceAccountingsByInboundInvoice = getFilterItemsByFieldSelector(state => state.inboundInvoices.accountings.records, 'inboundInvoiceId', Number)

export const getInitialInboundInvoiceAccountingByInboundInvoice = createCachedSelector(
  getInboundInvoiceAccountingsByInboundInvoice,
  findInitialAccounting
)((state, inboundInvoiceId) => `${inboundInvoiceId}`)

// @ts-expect-error selector not typed
export const getInboundInvoiceRowsByWork = getFilterItemsByFieldSelector(state => state.inboundInvoices.rows.records, 'workId', Number)

const getUnrejectedInboundInvoiceRowsByWork = createCachedSelector(
  (state, workId) => state.inboundInvoices.rows.records,
  (state, workId) => Number(workId),
  (state, workId) => getUnrejectedInboundInvoices(state),
  (inboundInvoiceRows, workId, unrejectedInboundInvoices) => {
    const inboundInvoiceIds = unrejectedInboundInvoices.map(inboundInvoice => inboundInvoice.id)
    return inboundInvoiceRows.filter(inboundInvoiceRow =>
      inboundInvoiceRow.type === inboundInvoiceRowTypes.INBOUND_INVOICE_ROW_TYPE_DEFAULT &&
      inboundInvoiceRow.workId === workId &&
      inboundInvoiceIds.includes(inboundInvoiceRow.inboundInvoiceId)
    )
  }
)((state, workId) => workId)

export const getInvoicableInboundInvoiceRowsByWork = createCachedSelector(
  getUnrejectedInboundInvoiceRowsByWork,
  inboundInvoiceRows => inboundInvoiceRows
    .filter(inboundInvoiceRow => !inboundInvoiceRow.workRowId)
)((state, workId) => `${workId}`)

export const getInboundInvoicesByRows = createCachedSelector(
  getInboundInvoicesFromArgument,
  (state, inboundInvoiceRows) => inboundInvoiceRows,
  (inboundInvoices, inboundInvoiceRows) =>
    removeDuplicates(inboundInvoiceRows.map(inboundInvoiceRow => inboundInvoiceRow.inboundInvoiceId))
      // @ts-expect-error selector not typed
      .map(inboundInvoiceId => findInboundInvoiceWithId(inboundInvoices, inboundInvoiceId)).filter(Boolean)
)((state, inboundInvoiceRows) => inboundInvoiceRows.map(inboundInvoiceRow => inboundInvoiceRow.id).join(','))

// @ts-expect-error selector not typed
export const getInboundInvoicesOfReceiver = getFilterItemsByFieldSelector(getInboundInvoicesFromArgument, 'receiverId', Number)

// @ts-expect-error selector not typed
export const getInboundInvoicesOfReceiverList = getFilterItemsByFieldWithValueArraySelector(getInboundInvoicesFromArgument, 'receiverId', value => value?.id ?? value)

export const findInboundInvoiceOccurrencesByTemplateInboundInvoiceId = createCachedSelector(
  getInboundInvoicesFromArgument,
  state => state.inboundInvoices.recurrenceOccurrencies.records,
  (state, templateInboundInvoiceId) => Number(templateInboundInvoiceId),
  (inboundInvoices, recurrenceOccurrences, templateInboundInvoiceId) => {
    const occurrences = recurrenceOccurrences.filter(recurrenceOccurrence => recurrenceOccurrence.templateInboundInvoiceId === templateInboundInvoiceId).map(recurrenceOccurrence => recurrenceOccurrence.inboundInvoiceId)
    // @ts-expect-error selector not typed
    return findInboundInvoicesWithIdList(inboundInvoices, occurrences)
  }
)((state, inboundInvoiceId) => `${inboundInvoiceId}`)

export const getInboundInvoiceRecurrenceRuleByInboundInvoiceId = createCachedSelector(
  // @ts-expect-error selector not typed
  getFilterItemsByFieldSelector(getRecurrenceRulesFromArgument, 'inboundInvoiceId', Number),
  recurrenceRules => recurrenceRules?.[0]
)((state, inboundInvoiceId) => `${inboundInvoiceId}`)
