import { isDefined } from '@evelia/common/helpers'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { ticketTypes } from '../constants'
import {
  getFindItemByIdSelector,
  getFindItemsByIdsSelector,
  getMemoSelector,
  getSubentitySelectors,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { createArgumentSelector } from '../helpers/typedSelectorHelpers'
import { selectFileEntities } from '../slices/filesSlice'
import { findAuthorsOfCurrentEmployee } from './authorSelectors'
import { findCurrentEmployee } from './employeeSelectors'
import { getTicketsStatesFromArgument } from './ticketStateSelectors'

export const getTicketsFromArgument = arg => arg.tickets ? arg.tickets.records : arg

export const findTicketWithId = getFindItemByIdSelector(getTicketsFromArgument)
export const findTicketsWithIds = getFindItemsByIdsSelector(getTicketsFromArgument)

export const getTicketsByTableIds = getTableIdsSelector('tickets')

export const findTicketsOfRelation = createCachedSelector(
  getTicketsFromArgument,
  (__state, relationIdKey) => relationIdKey,
  (__state, __relationType, relationId) => relationId ? Number(relationId) : null,
  (tickets, relationIdKey, relationId) => {
    if(relationIdKey && relationId) {
      return tickets.filter(ticket => ticket[relationIdKey] === relationId)
    }
    return tickets
  }
)((__state, relationIdKey, relationId) => `${relationIdKey}_${relationId}`)

export const findMemosByTicketId = getMemoSelector('tickets', 'ticketId')

export const {
  getJunctionData: getTicketEmployees
} = getSubentitySelectors('tickets', 'employees', 'employees', 'ticketId', 'employeeId')

export const getTicketFileLinks = createArgumentSelector(
  (state, __ticketId) => state.tickets.files.records,
  (__state, ticketId) => ticketId,
  (fileLinks, ticketId) => fileLinks.filter(fileLink => fileLink.ticketId === ticketId)
)

export const getFilesOfTicket = createArgumentSelector(
  selectFileEntities,
  getTicketFileLinks,
  (fileEntities, fileLinks) => fileLinks.map(fileLink => fileEntities[fileLink.fileId]).filter(isDefined)
)

const isTicketOwner = (ticket, authors) => authors.some(author => author.id === ticket.createdBy || author.id === ticket.templateCreatedBy)
const isAttachedToTicket = (ticketEmployeeIds, employee) => ticketEmployeeIds?.some(ticketEmployeeId => ticketEmployeeId === employee.id)
export const getHasTicketPermission = createCachedSelector(
  findTicketWithId,
  getTicketEmployees,
  findCurrentEmployee,
  findAuthorsOfCurrentEmployee,
  (ticket, ticketEmployees, employee, currentEmployeeAuthors) => {
    if(!employee || !ticket || !currentEmployeeAuthors?.length) {
      return false
    }
    if(!ticket.isPrivate || isTicketOwner(ticket, currentEmployeeAuthors)) {
      return true
    }

    return isAttachedToTicket(ticketEmployees.map(ticketEmployee => ticketEmployee.employeeId), employee)
  }

)((__state, ticketId) => `${ticketId}`)

export const getDefaultTickets = createSelector(
  getTicketsFromArgument,
  findCurrentEmployee,
  findAuthorsOfCurrentEmployee,
  getTicketsStatesFromArgument,
  (tickets, currentEmployee, currentEmployeeAuthors, ticketStates) => {
    return sortBy(tickets.filter(ticket => {
      return ticket.ticketSystemType === null && ticket.type === ticketTypes.TICKET &&
      (isTicketOwner(ticket, currentEmployeeAuthors) || isAttachedToTicket(ticket.employeeIds, currentEmployee) || (!ticket.isPrivate && (ticket.employeeRoleIds.length || ticket.employeeLevelId)))
    }), ['endDate', 'startDate', 'id'])
  })

export const findTicketOccurrenciesByTemplateTicketId = createCachedSelector(
  getTicketsFromArgument,
  (state, templateTicketId) => Number(templateTicketId),
  (tickets, templateTicketId) => {
    return tickets.filter(t => t.templateTicketId === templateTicketId)
  }
)((state, ticketId) => `${ticketId}`)
