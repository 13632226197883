import { accessLevels } from '@evelia/common/constants'
import type { FileModel } from '@evelia/common/types'
import { createCachedSelector } from 're-reselect'

import type { AuthorModel } from '../api/rtk/authorApi'
import type { EmployeeLevelModel } from '../api/rtk/employeeLevelsApi'
import type { GetListFn } from '../helpers/typedSelectorHelpers'
import { createEveliaSelector } from '../helpers/typedSelectorHelpers'
import { selectFiles } from '../slices/filesSlice'
import { findAuthorsOfCurrentEmployee } from './authorSelectors'
import { findCurrentEmployeeLevel, getEmployeeLevelsFromArgument } from './employeeSelectors'

const getFilesFromArgument: GetListFn<FileModel> = arg => Array.isArray(arg) ? arg : selectFiles(arg)
const getToolkitFileLinkFromArgument = (arg, selector) => Array.isArray(arg) ? arg : selector(arg)

const checkHasFileAccess = (file: FileModel, authors: AuthorModel[], currentEmployeeLevel: EmployeeLevelModel, employeeLevels: EmployeeLevelModel[]) => {
  if(!file) {
    return false
  }
  const authorIds = authors.map(author => author.id)
  const userAccessLevel = employeeLevels.find(employeeLevel => employeeLevel.accessLevel === accessLevels.USER)?.accessLevel
  const canSeeNonLimitedFiles = userAccessLevel && currentEmployeeLevel.accessLevel >= userAccessLevel
  const fileEmployeeLevel = employeeLevels.find(employeeLevel => employeeLevel.id === file.employeeLevelId)
  return (file.createdBy && authorIds.includes(file.createdBy)) ||
    // Restricted users have only access to their own files and ones with provided accessLevel
    (fileEmployeeLevel && fileEmployeeLevel.accessLevel <= currentEmployeeLevel.accessLevel) ||
    (canSeeNonLimitedFiles ? !file.employeeLevelId : false)
}

export const getFiles = createEveliaSelector(
  [
    getFilesFromArgument,
    findAuthorsOfCurrentEmployee,
    findCurrentEmployeeLevel,
    getEmployeeLevelsFromArgument
  ],
  (files: FileModel[], authors, currentEmployeeLevel, employeeLevels) => {
    const filterFile = (file: FileModel) => checkHasFileAccess(file, authors, currentEmployeeLevel, employeeLevels)
    return files.filter(filterFile)
  }
)

export const getHasFileAccess = createEveliaSelector(
  (__state, file) => file,
  findAuthorsOfCurrentEmployee,
  findCurrentEmployeeLevel,
  getEmployeeLevelsFromArgument,
  checkHasFileAccess
)

export const getFindToolkitFileLinksByPropertySelector = selector => createCachedSelector(
  state => getToolkitFileLinkFromArgument(state, selector),
  (state, targetField) => targetField,
  (state, targetField, targetValue) => targetValue,
  (fileLinks, targetField, targetValue) => fileLinks.filter(fileLink =>
    fileLink[targetField] === targetValue)
)((state, targetField, targetValue) => `${targetField}_${targetValue}`)
