import {
  useEffect,
  useRef,
  useState
} from 'react'
import type {
  ReactNode
} from 'react'
import type React from 'react'
import type {
  ReferenceType,
  UseFloatingOptions
} from '@floating-ui/react'
import {
  arrow,
  autoPlacement,
  autoUpdate,
  FloatingArrow,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

interface FloatingTooltipProps {
  disabled?: boolean
  tooltip: ReactNode
  floatingProps?: Omit<Partial<UseFloatingOptions<ReferenceType>>, 'open' | 'onOpenChange' | 'middleware'>
  children?: ReactNode
  divProps?: React.HTMLAttributes<HTMLDivElement>
  tooltipClassName?: string
  testId?: string
}

const FloatingTooltip = ({
  disabled,
  tooltip,
  floatingProps,
  children,
  divProps,
  tooltipClassName,
  testId
}: FloatingTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const {
    refs,
    floatingStyles,
    context,
    elements,
    update
  } = useFloating({
    strategy: 'fixed',
    ...floatingProps,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      autoPlacement(),
      arrow({
        element: arrowRef
      })
    ]
  })

  const hover = useHover(context, { mouseOnly: false, handleClose: safePolygon() })
  const click = useClick(context, { ignoreMouse: true, toggle: true })
  const dismiss = useDismiss(context, { referencePress: false, bubbles: false })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    dismiss
  ])

  // Updates the position of the tooltip, see more: https://floating-ui.com/docs/autoupdate
  useEffect(() => {
    if(isOpen && elements.reference && elements.floating) {
      const cleanup = autoUpdate(
        elements.reference,
        elements.floating,
        update
      )
      return cleanup
    }
  }, [isOpen, elements, update])

  return (
    <div
      onClick={e => {
      // Prevent default + stop propagation to not trigger buttons click event (in case this is used inside a button or checkbox or similar)
        e.preventDefault()
        e.stopPropagation()
      }}
      data-testid={testId}
    >
      <div
        ref={refs.setReference}
        {...divProps}
        {...getReferenceProps()}
      >
        {children}
      </div>
      {isOpen && !disabled && (
        <div
          id='tooltip'
          ref={refs.setFloating}
          className='tooltip show opacity-100'
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} fill='#565656' />
          <div className={clsx('tooltip-inner', tooltipClassName)}>
            {tooltip}
          </div>
        </div>
      )}
    </div>
  )
}

export interface FloatingIconTooltipProps extends FloatingTooltipProps {
  label?: React.ReactNode
  icon?: IconProp
  className?: string
  iconClassName?: string
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
}

export const FloatingIconTooltip = ({
  label,
  icon = faQuestionCircle,
  className,
  iconClassName = 'ms-1',
  iconProps,
  children,
  ...props
}: FloatingIconTooltipProps) => {
  return (
    <div id='icon-tooltip-container' className={clsx('d-inline-flex', className)}>
      {label}
      {children}
      {props.tooltip != null
        ? (
          <FloatingTooltip {...props}>
            <FontAwesomeIcon {...iconProps} icon={icon} className={clsx('tooltip-icon', iconClassName)} />
          </FloatingTooltip>
          )

        : null}
    </div>
  )
}

export default FloatingTooltip
