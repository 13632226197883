import type { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import type { SerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import uniqBy from 'lodash/uniqBy'
import queryString from 'query-string'

import contactActions from '../../actions/contactActions'
import customerActions from '../../actions/customerActions'
import projectActions from '../../actions/projectActions'
import targetActions from '../../actions/targetActions'
import ticketActions from '../../actions/ticketActions'
import type { DefaultArgs } from '../../components/Kanban/Kanban'
import type { ApiResponse } from '.'
import { getBaseQuery } from './apiHelpers'
import type { TicketsKanbanModel } from './ticketsKanbanApi'

export type TicketModel = {
  id: number
  ticketTypeId: number | null
  ticketStateId: number
  employeeLevelId: null
  workId: number | null
  invoiceId: number | null
  inboundInvoiceId: null
  targetId: number | null
  customerId: number | null
  receiptId: number | null
  receiverId: number | null
  projectId: number | null
  contactId: number | null
  employeeRoleIds: number[]
  employeeIds: number[]
  startDate: null | string
  endDate: null | string
  createdAt: string
  updatedAt: string
  createdBy: number | null
  description: string
  details: unknown
  ticketNumber: number
  isPrivate: boolean
  type: string
  templateTicketId: number | null
  templateCreatedBy: number | null
}

const TICKETS_DEFAULT_LIMIT = 25

const serializeTicketsQueryArgs: SerializeQueryArgs<DefaultArgs, Partial<DefaultArgs>> = data => {
  const newQueryArgs = { ...data.queryArgs as DefaultArgs }

  delete newQueryArgs.page
  delete newQueryArgs.limit
  delete newQueryArgs.extraFilters
  delete newQueryArgs.cacheBust
  delete newQueryArgs.sort
  return newQueryArgs
}

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: getBaseQuery('tickets'),
  endpoints: builder => ({
    getTickets: builder.query<ApiResponse<TicketModel>, DefaultArgs>({
      query: query => {
        const {
          mainFilter,
          extraFilters = [],
          page,
          limit,
          sort
        } = query
        const extraFiltersFormatted = extraFilters.flatMap(extraFilter => extraFilter.values.map(value => [extraFilter.field, value]))
        const filters = (extraFiltersFormatted as (string | number | boolean)[][]).concat([mainFilter])
        const orderBy = sort?.sortKey
        const sortOrder = sort?.order

        const q = queryString.stringify({ filters, page, limit: limit ?? TICKETS_DEFAULT_LIMIT, orderBy, sortOrder })
        return `?${q}`
      },
      serializeQueryArgs: serializeTicketsQueryArgs,
      merge: (currentCache, newItems, { arg }) => {
        if(currentCache.records && !arg.cacheBust) {
          return {
            // Prevent storing duplicate items
            records: uniqBy([...currentCache.records, ...newItems.records].reverse(), 'id').reverse(),
            _embedded: { ...newItems._embedded }
          }
        }
        return newItems
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.page !== previousArg?.page
      },
      keepUnusedDataFor: Infinity
    })
  })
})

export const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, _embedded: ApiResponse<TicketModel | TicketsKanbanModel>['_embedded']) => {
  // Dispatch embedded data into base redux state
  dispatch(contactActions.fetchSuccess(_embedded.contacts ?? []))
  dispatch(customerActions.fetchSuccess(_embedded.customers ?? []))
  dispatch(projectActions.fetchSuccess(_embedded.projects ?? []))
  dispatch(targetActions.fetchSuccess(_embedded.targets ?? []))
  dispatch(targetActions.fetchSuccess(_embedded.work?.records ?? []))
}

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: ticketsApi.endpoints.getTickets.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      // Dispatch work into base redux state
      listenerApi.dispatch(ticketActions.fetchSuccess(action.payload.records))
    }
    if(_embedded) {
      handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
    }
  }
})

export const { middleware: ticketsApiMiddleware } = listenerMiddleware
